import propTypes from 'prop-types';

import { useState } from 'react';
import Wrapper from '../../layout/Wrapper';
import ExpandedCard from './ExpandedCard';
import CareerCard from './CareerCard';
//import useClickOutside from '../../hooks/useClickOutside';
import useComponentVisible from '../../hooks/useClickOutside';

const api_url = process.env.REACT_APP_API_URL;



export default function CardCareers2({ cards }) {
  const [expandedCard, setExpandedCard] = useState({});

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);



  return (

    <Wrapper>
      <div ref={ref}>
        {isComponentVisible ?
         <ExpandedCard card={expandedCard} setExpanded={setIsComponentVisible} setExpandedCard={setExpandedCard}></ExpandedCard>
         :
        <div className='flex flex-col md:flex-row justify-center items-center w-full gap-8'>
          {/* {
            cards.map((item, index) => (
              <CareerCard card={item} key={index} setExpanded={setIsComponentVisible} setExpandedCard={setExpandedCard} />
            ))
          } */}
          <CareerCard card={cards.NetDev} setExpanded={setIsComponentVisible} setExpandedCard={setExpandedCard} />
          {/* <CareerCard card={cards.NetDevIntern} setExpanded={setIsComponentVisible} setExpandedCard={setExpandedCard} /> */}

        </div>
}
      </div>
       
    </Wrapper>
  );
}

CardCareers2.propTypes = {
};
