import React from 'react';
import Wrapper from '../../layout/Wrapper';
import FacelessSlider from './FacelessSlider/FacelessSlider';
import SliderWrapper from './SliderWrapper';

const AboutUsSlider = () => {
  return (
    <Wrapper padding={" py-90p"}>
      <SliderWrapper>
        <FacelessSlider />
      </SliderWrapper>
    </Wrapper>
  );
};

export default AboutUsSlider;
