import ActionCard from '../components/shared/ActionCard';
import PageLayout from '../layout/PageLayout';

import net from './../assets/icons/caseStudy/net.svg';
import powerBi from './../assets/icons/caseStudy/power-bi.svg';
import powerAutomate from './../assets/icons/caseStudy/power-automate.svg';
import mssql from './../assets/icons/caseStudy/MSSQL.svg';
import dataFactory from './../assets/icons/caseStudy/data-factory.svg';
import { useEffect } from 'react';
import Wrapper from '../layout/Wrapper';
import TechNuggets from '../components/shared/TechNuggets';
import { motion } from 'framer-motion';
import useAnalytics from '../hooks/useAnalytics';

const numbers = [
  {
    value: 7,
    static: '+',
    title: 'Team Members',
  },
  {
    value: 100,
    static: '+',
    title: 'Users',
  },
  {
    value: '1,6m',
    static: '+',
    title: 'Transactions per Year',
  },
];

const _data = {
  heading: {
    subheading: 'Case Study',
    heading: 'BI Healthcare Solution',
    imgUrl:
      'https://lh3.googleusercontent.com/QkLcHNOGmWIB2t3fwZd69ZwJpHOYcTTAPmPs4zbjErDbp9nXbTw0vhktPCuCM2EWZUU=w2400',
    paragraph:
      'BI Solution that provides an uncommon level of insights into the healing process.',
  },

  about: {
    heading: 'About the Client',
    paragraph:
      'Healthcare company has a centralized IT solution for tracking every aspect of their own business, especially services provided to clients and working times of their clinicians in all clinics they own all around the USA.',
    country_heading: 'Country',
    country_name: 'USA',
    industry_heading: 'Industry',
    industry_name: 'Healthcare',
  },

  domain: {
    heading: 'Domain',
    paragraph:
      'BI Solution helps clinicians to improve their work and create a wider picture of client progress during the healing process. The solution offers our customers a high level of insight into their cash flows and workforce organization. In one word, this is a fully customizable solution for a comprehensive business process.',
  },

  challanges: {
    heading: 'Challanges',
    paragraph:
      'The main objective is to create a solution that meets the requirements of stakeholders while integrating with existing IT systems to get data without impacting the system. The solution should use only an MS stack of technologies while taking care of sensitive data, and maintaining and providing up-to-date data. The solution should also be a comfortable and reliable, user-friendly solution for everyday work.',
  },

  solution: {
    heading: 'Solution',
    paragraph:
      'The purpose of interactive data visualization is to allow users to explore and understand data. We used the Power BI analytics service to accomplish this. Azure has several features that automate data processing and ensure the reliability and security of the data.',
    imgUrl:
      'https://lh3.googleusercontent.com/S5ffnZQcALDACIagcAA6WwdsYrwOHK-TKz79BUMOu4vU7ixhINcBXx0vMXYnbicOcQ8=w2400',
  },

  results: {
    heading: 'Results',
    list: [
      {
        id: 1,
        text: 'Visualize information to ensure better data understanding',
      },
      {
        id: 2,
        text: 'Deliver the product within an established amount of time and defined budgets',
      },
      {
        id: 3,
        text: 'Be up to date with the latest stack of technologies',
      },
      {
        id: 4,
        text: 'Offer services from ETL with complex data processing to data visualization',
      },
    ],
  },

  technologies: [
    {
      id: 1,
      link: net,
    },
    {
      id: 2,
      link: powerBi,
    },
    {
      id: 3,
      link: powerAutomate,
    },
    {
      id: 4,
      link: mssql,
    },
    {
      id: 5,
      link: dataFactory,
    },
  ],
};

export default function CaseStudyBI() {
  useEffect(() => {
    document.title = 'Case Study: BI Healthcare Solution';
  }, []);

  useAnalytics('Case Study: BI Healthcare Solution');

  return (
    <PageLayout>
      <div className="bg-baby-blue dark:bg-dg-primary-1700 w-full pt-20 md:pt-24">
        {/* Heading Section */}
        <section
          id="heading"
          className="flex flex-col items-center justify-center m-auto py-16 md:py-32 bg-[url('https://lh3.googleusercontent.com/QkLcHNOGmWIB2t3fwZd69ZwJpHOYcTTAPmPs4zbjErDbp9nXbTw0vhktPCuCM2EWZUU=w2400')] bg-cover"
        >
          <div className="my-8 flex flex-col md:flex-row justify-start items-center w-full max-w-custom px-8 xl:px-0">
            <div className="w-full">
              <h6 className="subheading">{_data.heading.subheading}</h6>
              <h1 className="heading text-dg-secondary mt-2">{_data.heading.heading}</h1>
              <p className="paragraph mt-4">{_data.heading.paragraph}</p>
            </div>
          </div>
        </section>

        <Wrapper padding={' py-90p'}>
          <motion.section
            id="status-numbers"
            className="flex flex-col md:flex-row items-start justify-between w-full gap-90p px-90p"
            initial={{ y: 60, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            {numbers.map((item, i) => (
              <div key={i} className="flex flex-col">
                <h2 className="display-number text-center">
                  {item.value}
                  {item.static}
                </h2>
                <h3 className="number-title text-center">{item.title}</h3>
              </div>
            ))}
          </motion.section>
        </Wrapper>

        {/* About the Client Section */}
        <section id="client" className="flex flex-col items-center justify-center">
          <div className="my-8 flex flex-col md:flex-row justify-center items-start w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full md:w-1/2">
              <h3 className="h3-heading text-teal-600">{_data.about.heading}</h3>
              <p className="paragraph mt-4">{_data.about.paragraph}</p>
            </div>
            <div className="w-full md:w-1/2 grid grid-cols-2 md:grid-cols-3 gap-16 mt-8 md:mt-0">
              <div className="hidden md:inline-block"></div>
              <div className="float-left md:float-right text-left md:text-right">
                <h4 className="text-teal-600 font-semibold">
                  {_data.about.country_heading}
                </h4>
                <p className="mt-4">{_data.about.country_name}</p>
              </div>
              <div className="float-left md:float-right text-left md:text-right">
                <h4 className="text-teal-600 font-semibold">
                  {_data.about.industry_heading}
                </h4>
                <p className="mt-4">{_data.about.industry_name}</p>
              </div>
            </div>
          </div>
        </section>

        {/* Domain Section */}
        <section id="domain" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading">{_data.domain.heading}</h3>
              <p className="paragraph mt-4">{_data.domain.paragraph}</p>
            </div>
          </div>
        </section>

        {/* Challanges, Solution Section */}
        <section
          id="challanges_solution"
          className="flex flex-col items-center justify-center mt-16"
        >
          <div className="my-8 flex flex-col md:flex-row justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full md:w-1/2 md:pr-16">
              <div>
                <h3 className="h3-heading">{_data.challanges.heading}</h3>
                <p className="text-sm text-dark-gray dark:text-white mt-4">
                  {_data.challanges.paragraph}
                </p>
              </div>
              <div className="mt-8">
                <h3 className="h3-heading">{_data.solution.heading}</h3>
                <p className="text-sm text-dark-gray dark:text-white mt-4">
                  {_data.solution.paragraph}
                </p>
              </div>
            </div>
            <img
              src={_data.solution.imgUrl}
              alt="Case Study main image"
              className="text-center w-full md:w-1/2"
            />
          </div>
        </section>

        {/* Results Section */}
        <section id="results" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading text-dg-secondary">{_data.results.heading}</h3>
              <ul className="list-disc paragraph mt-2 pl-8">
                {_data.results.list.map(item => (
                  <li key={item.id}>{item.text}</li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        {/* Technologies Section */}
        <section id="technologies" className="flex flex-col justify-center mt-16">
          <div className="my-8 flex flex-col w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading">Technologies</h3>
            </div>
            <TechNuggets
              tech={[
                'Azure Data Factory',
                'Azure SQL Server',
                'Microsoft Power BI',
                '.Net Core',
                'Azure App Services',
                'Microsoft Power Automate',
                'Azure Logic Apps',
                'Azure Active Directory',
              ]}
            />
          </div>
        </section>

        {/* CTA Section */}
        <section id="cta" className="flex flex-col items-center justify-center mt-16">
          <div className="px-8 mt-8 mb-32 w-full max-w-custom">
            <ActionCard
              title="Let's Work Together!"
              text="Grow faster with a dedicated team of .NET & JS experts."
              btn1="More Projects"
              btn2="Contact Us"
              link1={'/portfolio'}
              link2={'/contact'}
            />
          </div>
        </section>
      </div>
    </PageLayout>
  );
}
