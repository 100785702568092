import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';

import logo from '../../assets/logos/LogoFull.svg';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import '../../App.css';
import Wrapper from '../../layout/Wrapper';
import menuIcon from './../../assets/icons/menu.svg';
import chevron from './../../assets/chevron.svg';
import { useState } from 'react';
import useHoverOtuside from '../../hooks/useHoverOutside';
import { ref } from 'yup';

export default function Navigation({ links, scrollToView, activeLinks, forwardedRef }) {
  const location = useLocation();
  const home = useNavigate();
  function handleLogo() {
    home('/');
  }

  const { ref, show, setShow } = useHoverOtuside(false);

  function showDrop() {
    setShow(!show);
  }

  // Check if you are on HomePage to scroll to Contact us, or open a sepperate Contact us Page
  function checkUrl(event) {
    if (location.pathname === '/') scrollToView(event);
    else home('/contact');
  }

  return (
    <Popover className="bg-white dark:bg-dg-primary-1700 fixed w-full top-0 z-50">
      <Wrapper>
        <div className="flex justify-between items-center border-gray-100 dark:border-dg-primary-1800 py-6 md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="#">
              <span className="sr-only">Diligent</span>
              <img
                className="h-8 w-auto sm:h-10"
                src={logo}
                alt="Diligent logo"
                onClick={() => handleLogo()}
              />
            </a>
          </div>
          <div className="mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center  text-dg-primary-900 focus:outline-none">
              <img src={menuIcon} alt="Menu" className="h-[30px]" />
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="hidden md:flex space-x-8 lg:space-x-10 items-center"
          >
            {links.map((item, index) =>
              (item.drop && item.drop.length > 0) ? (
                <div>
                  <a
                    href={item.path}
                    onMouseOver={() => showDrop()}
                    id="dropdownNavbarLink"
                    className="flex items-center justify-between w-full py-2 pl-3 pr-4 font-medium rounded text-gray-500 hover:text-dg-primary-900 focus:text-dg-primary-900 active:text-dg-primary-900 md:dark:hover:bg-transparent"
                  >
                    Careers
                    <svg
                      className="w-5 h-5 ml-1"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                  <div
                    ref={ref}
                    id="dropdownNavbar"
                    className={
                      (show ? 'absolute ' : 'hidden ') +
                      'z-10 font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600'
                    }
                  >
                    <ul className="py-8 px-8 text-sm text-gray-500 dark:text-gray-400">
                      {item.drop.map((item2, index2) => (
                        <li key={index2}>
                          <a
                            href={item2.path}
                            className="text-p font-medium text-gray-500 dark:text-white hover:text-dg-primary-900 focus:text-dg-primary-900 active:text-dg-primary-900 capitalize whitespace-nowrap"
                          >
                            {item2.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <NavLink
                  key={index}
                  to={item.path}
                  className={({ isActive }) => {
                    return `text-p font-medium dark:text-white hover:text-dg-primary-900 focus:text-dg-primary-900 active:text-dg-primary-900 capitalize ${activeLinks(
                      isActive,
                    )}`;
                  }}
                >
                  {item.title}
                </NavLink>
              ),
            )}
            {/* React Router Link does not support Anchor tags */}
            <div
              onClick={event => checkUrl(event)}
              href="#contact"
              className="contact-us-link text-p"
            >
              Contact
            </div>
            {/* DARK MODE BUTTON */}
            {/* <div
              className="text-dark-gray font-medium text-base capitalize dark:text-white hover:cursor-pointer"
              onClick={() => {document.body.classList.toggle('dark'); localStorage.setItem('darkMode', 'dark'); }}
            >
              💡
            </div> */}
          </Popover.Group>
        </div>
      </Wrapper>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          static
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          {({ close }) => (
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto cursor-pointer"
                      src={logo}
                      alt="Diligent"
                      onClick={() => handleLogo()}
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center transition-all text-dg-primary-900 hover:text-white hover:transition-all hover:bg-dg-primary-900 focus:outline-none">
                      <p className="font-semibold">x</p>
                      {/*<span className="sr-only">Close menu</span>
                      <div className="h-6 w-6" aria-hidden="true" />*/}
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {links.map((item, index) =>
                      item.drop ? (
                        <>
                          <NavLink
                            onClick={() => close()}
                            key={index}
                            to={item.path}
                            className="text-p p-3 flex justify-center items-center rounded-md transition-all hover:transition-all hover:bg-dg-primary-900 hover:text-white"
                          >
                            <div aria-hidden="true" />
                            <span className="ml-3 text-base font-medium text-inherit">
                              {item.title}
                            </span>
                          </NavLink>
                          {item.drop.map((item2, index2) => (
                            <NavLink
                              onClick={() => close()}
                              key={index2}
                              to={item2.path}
                              className="text-p p-3 flex justify-center items-center rounded-md transition-all hover:transition-all hover:bg-dg-primary-900 hover:text-white"
                            >
                              <div aria-hidden="true" />
                              <span className="ml-3 text-base font-medium text-inherit">
                                {item2.title}
                              </span>
                            </NavLink>
                          ))}
                        </>
                      ) : (
                        <NavLink
                          onClick={() => close()}
                          key={index}
                          to={item.path}
                          className="text-p p-3 flex justify-center items-center rounded-md transition-all hover:transition-all hover:bg-dg-primary-900 hover:text-white"
                        >
                          <div aria-hidden="true" />
                          <span className="ml-3 text-base font-medium text-inherit">
                            {item.title}
                          </span>
                        </NavLink>
                      ),
                    )}
                    <a
                      href="#contact"
                      onClick={event => {
                        close();
                        checkUrl(event);
                      }}
                      className="contact-us-link flex justify-center items-center text-p"
                    >
                      <span className="ml-3 text-base font-medium text-inherit">
                        Contact
                      </span>
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

Navigation.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
  scrollToView: PropTypes.func,
  activeLinks: PropTypes.func,
};
