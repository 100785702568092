import { Field, ErrorMessage } from 'formik';

const tags = [
  { id: 0, name: '#' },
  { id: 1, name: '#job' },
  { id: 2, name: '#outsourcing' },
  { id: 3, name: '#project' },
  { id: 4, name: '#partnership' },
];

export default function TagInput({ changeFormHandler, props }) {
  return (
    <div>
      <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
        Subject
      </label>
      <div className="flex">
        <div className="mt-1 relative rounded-md">
          <select
            name="tag"
            id="tag"
            onBlur={changeFormHandler}
            value={props.values.tag}
            onChange={props.handleChange}
            className="form-select mr-2 focus:ring-dg-primary-600 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
            style={{height:'39px'}}
          >
            {tags.map(option => (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-1 relative rounded-md grow">
          {/* <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div> */}
          <input
            type="text"
            name="subject"
            id="subject"
            placeholder='Subject'
            value={props.values.subject}
            onChange={props.handleChange}
            onBlur={changeFormHandler}
            className="focus:ring-dg-primary-600 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white block w-full sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
          />
          <div className="h-4">
            <ErrorMessage name="subject" component="div" className="text-sm text-right text-red-600" />
          </div>
        </div>
      </div>
      <label htmlFor="subjectRules" className="block text-sm font-medium text-gray-700">
        You can use our #Tags for specific subjects <br /> or you can write to us about
        what ever you like!
      </label>
    </div>
  );
}
