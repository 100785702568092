import { useState, useEffect } from 'react';
import axios from 'axios';

import CasesSection from './../components/CasesSection';
import Contact from '../components/shared/Contact';

import Animation_Diligent from '../assets/animation_diligent.webm';
import Wrapper from '../layout/Wrapper';
import PageTitle from '../components/shared/PageTitle';
import PortfolioCard from '../components/PortfolioCard';
import Grid from '../layout/Grid';
import CardsGrid from '../components/CardsGrid';
import PageLayout from '../layout/PageLayout';

import StrataThumb from './../assets/images/CaseStudy/StrataThumb.jpg';
import useAnalytics from '../hooks/useAnalytics';
import ReactHelmet from '../components/shared/ReactHelmet';
import { strapiApiBuilder } from '../utils/strapiApiBuilder';
import useDataApi from '../hooks/useDataApi';
import PortfolioDataLayer from '../api/PortfolioDataLayer';

const _data = {
  heading: {
    heading: 'Case Studies',
    subheading: 'Our Work',
    paragraph:
      "Our philosophy is based on the idea of ​​innovation. We work tirelessly to provide useful solutions that are consistent with the strategic priorities of our clients. That is the key that differentiates Diligent Software's portfolio of services: our relentless motivation to create value for companies that rely on digital solutions.",
  },
  cards: [
    {
      id: 0,
      title: 'BI Healthcare Solution System',
      imgUrl:
        'https://lh6.googleusercontent.com/D7N87i3udAln4YBp5SbaSI-9r2pVnnT5K2VT6p0G3dQanVgTMC2tdgz71PWOYco-7yQ=w2400',
      alt: 'BI Healthcare Solution System',
      link: '/casestudybi',
    },
    {
      id: 1,
      title: 'Resource Planning System',
      imgUrl:
        'https://lh5.googleusercontent.com/HLOh5coHfcEgDuftj1pOA9f1865xiIom5vyxTWNMKqMiivxL8Lg4c9ACzbfYYUdeuqQ=w2400',
      alt: 'Resource Planning System',
      link: '/casestudyresource',
    },
    {
      id: 2,
      title: 'Ticketing System for Passengers',
      imgUrl:
        'https://lh5.googleusercontent.com/f_G0H0C_qLHhsU8PBj6uTkNigzKiXzd24B_pgJ6UqVmBKlU2Lyxv2r5lf6uvY9d_0PY=w2400',
      alt: 'Ticketing System for Passengers',
      link: '/casestudyticketing',
    },
    {
      id: 3,
      title: 'Financial Engine',
      imgUrl:
        'https://lh3.googleusercontent.com/n-vcF1h8o4o3_CWZXoT-BQDLGFjL3_wt1twsDajycp8IhiiA2UkBoecsGd-ly_LRxJI=w2400',
      alt: 'Financial Engine',
      link: '/casestudyfinancial',
    },
    {
      id: 4,
      title: 'Centralized Monitoring System',
      imgUrl:
        'https://lh4.googleusercontent.com/CwrSwX4UVcU-nYhEk4wWqsK8TUJbt881kVx2i4ryXYJ3xIsN8d7F1bY0qO_0thHCbBM=w2400',
      alt: 'Centralized Monitoring System',
      link: '/casestudycentralized',
    },
    {
      id: 5,
      title: 'Health Tracking Software',
      imgUrl: 'https://i.postimg.cc/HsKGcXVv/Strata-Thumb.jpg',
      alt: 'Health Tracking Software',
      link: '/casestudystrata',
    },
  ],
};

const strapiPopulate = [
  'Heading',
  'Heading.subtitle',
  'Heading.title',
  'Problems',
  'Highlighted',
  'Highlighted.title',
  'Highlighted.paragraph',
  'WhyWork',
  'WhyWork.img',
  'Stats',
  'SucessParagraph',
  'Specs',
  'Download',
  'OfficeImg',
  'WorkTogether',
  'SEO',
  'SEO.metaSocial',
  'SEO.metaImage',
  'SEO.metaSocial.image',
];

// const [{ data, isLoading, isError }, doFetch] = useFetchCollections(strapi);

const api_url = process.env.REACT_APP_API_URL;

export default function Portfolio() {
  const [cnt, setCnt] = useState('');
  const [isLoaded, setIsLoaded] = useState('');

  
  const strapi = strapiApiBuilder('portfoliopage', strapiPopulate, '');

  const [{ data, isLoading, isError }, doFetch] = useDataApi(strapi);


  useAnalytics('Case Studies');

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  }

  return (
    <PageLayout>
      {data && data.SEO && <ReactHelmet seo={data.SEO} />}
      <div className="flex flex-col gap-90p pt-32">
        <Wrapper>
          <h1 className="hidden">Our Work - Case Studies</h1>
          <div className="flex flex-col gap-8p">
            <PageTitle
              heading={_data.heading.heading}
              subheading={_data.heading.subheading}
              left
              color
            />
            <p className="paragraph">{_data.heading.paragraph}</p>
          </div>
        </Wrapper>
        <Wrapper>
          <PortfolioDataLayer/>
        </Wrapper>
      </div>
    </PageLayout>
  );
}
