import propTypes from 'prop-types';

import CaseStudy from './../components/CaseStudy';

export default function CasesSection(props) {
  const caseStudy = props.fetch;

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="w-full bg-baby-blue dark:bg-dg-primary-1600 py-8 mt-8">
        <div className="text-center md:text-left max-w-custom mx-auto">
          <h2 className="text-4xl text-dg-secondary uppercase font-medium">
            {props.heading}
          </h2>
        </div>
      </div>
      {caseStudy.case_study.map(study => (
        <CaseStudy
          key={study.id}
          heading={study.heading}
          engineers={study.engineers}
          image={study.image.data.attributes.formats.large.url}
          goals_heading={study.goals_heading}
          goals_paragraph={study.goals_paragraph}
          challange_heading={study.challange_heading}
          challange_paragraph={study.challange_paragraph}
          solution_heading={study.solution_heading}
          solution_paragraph={study.solution_paragraph}
          client_heading={study.client_heading}
          client_paragraph={study.client_paragraph}
          summary_heading={study.summary_heading}
          summary_paragraph={study.summary_paragraph}
          fetch={study}
        />
      ))}
    </div>
  );
}

CasesSection.propTypes = {
  heading: propTypes.string,
  key: propTypes.number,
  fetch: propTypes.string,
};
