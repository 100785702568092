import propTypes from 'prop-types';

import img from '../../assets/Group 305.png';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { JobFormContext } from '../../context';
import { useContext, useState, useRef, useEffect } from 'react';
import * as emailjs from 'emailjs-com';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga4';
import MyDropzone from './MyDropzone';
import HashPositions from './HashPositions';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { UIContext } from '../../context/UIContextProvider';
import { strapiApiBuilder } from '../../utils/strapiApiBuilder';
import useFetchCollections from '../../hooks/useFetchCollections';

const api_url = process.env.REACT_APP_API_URL;

const strapiPopulate = [];

export default function JobForm(props) {
  const [btnText, setBtnText] = useState('');
  const [sucMsg, setSucMsg] = useState(false);
  const captchaRef = useRef(null);
  const [msgText, setMsgText] = useState('');
  const cntCareersJobs = props.cntCareers;
  let defaultPositionSelection = props.defaultPositionSelection;

  //search context for prevous entry TODO
  const { jobForm, setJobForm } = useContext(UIContext);

  const [otherInputState, setOtherInputState] = useState(true);
  const [selectedPosition, setSelectedPosition] = useState(jobForm.position);
  //console.log(selectedPosition);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorMsgPosition, setErrorMsgPosition] = useState('');
  const fileInput = useRef();

  const strapi = strapiApiBuilder('jobs', strapiPopulate, '');

  const [{ data, isLoading, isError }, doFetch] = useFetchCollections(strapi);

  function changeFormHandler(event) {
    const { name, value } = event.target;
    setJobForm(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  function dropzoneToFormData(files) {
    setJobForm(prevState => ({
      ...prevState,
      file: files,
    }));
  }

  function hashToFormData(selected, other) {
    setJobForm(prevState => ({
      ...prevState,
      position: selected,
      other: other,
    }));
  }

  useEffect(() => {
    setJobForm(prevState => ({
      ...prevState,
      position: selectedPosition,
    }));
  }, [selectedPosition]);

  useEffect(() => {
    setBtnText(props.cta);
  }, [props.cta]);

  useEffect(() => {
    if (jobForm.file !== '') {
      setErrorMsg('');
    }
  }, [jobForm.file]);

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Email is Required'),
    firstName: Yup.string()
      .min(2, 'First name too short')
      .max(50, 'First name too long')
      .required('First Name is Required'),
    lastName: Yup.string()
      .min(2, 'Last name too short')
      .max(50, 'Last name too long')
      .required('Last name is Required'),
    coverLetter: Yup.string()
      .trim()
      .min(2, 'Cover Letter too short')
      .required('Cover Letter is Required'),
    other: Yup.string(),
  });

  return (
    <div className="mt-10 sm:mt-0 mx-auto">
      <div className="md:grid md:grid-cols-2 md:gap-6">
        <motion.div
          className="mt-5 md:mt-0 md:col-span-1"
          initial={{ x: -60, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -60, opacity: 0 }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
        >
          <Formik
            initialValues={jobForm}
            validationSchema={validationSchema}
            onChange={changeFormHandler}
            onSubmit={async values => {
              const prepFile = async file => {
                if (file.size >= 2000000) {
                  setErrorMsg('File too large!');
                  return null;
                } else {
                  const base64 = await convertBase64(file);
                  return base64;
                }
              };
              const convertBase64 = file => {
                return new Promise((resolve, reject) => {
                  const fileReader = new FileReader();

                  fileReader.readAsDataURL(file);
                  fileReader.onload = () => {
                    resolve(fileReader.result);
                  };

                  fileReader.onerror = error => {
                    reject(error);
                  };
                });
              };
              if (
                selectedPosition === '' ||
                (otherInputState === false && values.other === '')
              )
                setErrorMsgPosition('Position is Required');
              else {
                setSelectedPosition(jobForm.position);
                setErrorMsgPosition('');
                if (jobForm.file === '') {
                  setErrorMsg('CV is Required');
                } else {
                  setErrorMsg('');
                  if (jobForm.file.size >= 2000000) {
                    setErrorMsg('File too large!');
                  } else {
                    const file = {
                      filename: 'CV.pdf',
                      data: jobForm.file,
                    };

                    const token = captchaRef.current.getValue();
                    captchaRef.current.reset();
                    if (token.length === 0) {
                      setSucMsg(true);
                      setMsgText('Please fill reCAPTCHA and try again. Thank you!');
                    } else {
                      await axios
                        .post(`${process.env.REACT_APP_CAPTCHA_API}/verify-token`, {
                          token,
                        })
                        .then(res => {
                          setSucMsg(true);
                          if (res.data.data.success) {
                            setMsgText('Submission Succesful! Thank you!');

                            values.position = jobForm.position;

                            props.mg.messages.create('dilig.net', {
                              from: `${values.firstName} ${values.lastName} <${values.email}>`,
                              to: ['hr@dilig.net'],
                              subject: 'Applying for a position',
                              text: `Email: ${values.email}, Position:${values.position} ${values.other}, Cover letter: ${values.coverLetter}, Link: ${values.link} `,
                              html: `<p>Email: ${values.email}</p><p>Position: ${values.position} ${values.other}</p><p>Cover letter: ${values.coverLetter}</p><p>Link (optional): ${values.link}</p>`,
                              attachment: file,
                            });
                          } else
                            setMsgText('Please fill reCAPTCHA and try again. Thank you!');
                        })
                        .catch(error => {
                          console.log(error);
                        });
                    }
                  }
                }
              }
              ReactGA.event('contact', {
                category: 'Contact',
                action: 'Job application',
              });
            }}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <div className="sm:rounded-md">
                  <div className="py-2 sm:py-6">
                    <div className="">
                      <div className="col-span-1 sm:col-span-1">
                        <div className="hidden py-1">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                          >
                            Position
                          </label>
                          <input
                            type="text"
                            name="position"
                            id="position"
                            onBlur={changeFormHandler}
                            value={selectedPosition}
                            onChange={props.handleChange}
                            autoComplete="given-name"
                            className="mt-1 disabled:bg-gray-100 disabled:border-gray-300 focus:ring-dg-primary-600 focus:border-dg-primary-900 block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                          />
                          <div className="h-4">
                            <ErrorMessage
                              name="position"
                              component="div"
                              className="text-sm text-right text-red-600"
                            />
                          </div>
                        </div>
                        {/* <RadioBoxesGroup/> */}
                        {data && (
                          <HashPositions
                            changeFormHandler={changeFormHandler}
                            data={data}
                            otherInputState={otherInputState}
                            hashToFormData={hashToFormData}
                            setOtherInputState={setOtherInputState}
                            defaultPositionSelection={defaultPositionSelection}
                            selectedPosition={selectedPosition}
                            setSelectedPosition={setSelectedPosition}
                          />
                        )}

                        <div className="py-1">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                          >
                            Other
                          </label>
                          <input
                            disabled={otherInputState ? 'disabled' : ''}
                            type="text"
                            name="other"
                            id="other"
                            value={props.values.other}
                            onChange={props.handleChange}
                            className="mt-1 disabled:bg-gray-100 disabled:border-gray-300 dark:disabled:bg-gray-400 dark:disabled:border-gray-600 focus:ring-dg-primary-600 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                          />
                        </div>
                        {errorMsgPosition != '' ? (
                          <div className="h-4">
                            <div className="text-sm text-right text-red-600">
                              {errorMsgPosition}
                            </div>
                          </div>
                        ) : null}
                        <div className="py-1">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                          >
                            First name
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            onBlur={changeFormHandler}
                            value={props.values.firstName}
                            onChange={props.handleChange}
                            autoComplete="name"
                            autofill="true"
                            className="mt-1 focus:ring-dg-primary-600 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                          />
                          <div className="h-4">
                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="text-sm text-right text-red-600"
                            />
                          </div>
                        </div>
                        <div className="py-1">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                          >
                            Last name
                          </label>
                          <input
                            onBlur={changeFormHandler}
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={props.values.lastName}
                            onChange={props.handleChange}
                            autoComplete="family-name"
                            autofill="true"
                            className="mt-1 focus:ring-dg-primary-900 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                          />
                          <div className="h-4">
                            <ErrorMessage
                              name="lastName"
                              component="div"
                              className="text-sm text-right text-red-600"
                            />
                          </div>
                        </div>
                        <div className="py-1">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                          >
                            Email
                          </label>
                          <input
                            onBlur={changeFormHandler}
                            type="email"
                            name="email"
                            id="email"
                            value={props.values.email}
                            onChange={props.handleChange}
                            autoComplete="email"
                            autofill="true"
                            className="mt-1 focus:ring-dg-primary-900 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                          />
                          <div className="h-4">
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-sm text-right text-red-600"
                            />
                          </div>
                        </div>
                        {/* <div className="form-check py-2">
                                  <input
                                    className="form-check-input appearance-none h-6 w-6 border text-dg-primary-900 border-dg-primary-600 rounded-sm bg-white checked:dg-primary-900 checked:border-dg-primary-900 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer focus:ring-dg-primary-900"
                                    type="checkbox"
                                    value=""
                                    id="meeting"
                                  />
                                  <label
                                    className="ml-3 form-check-label inline-block text-gray-800"
                                    htmlFor="meeting"
                                  >
                                    Schedule a meeting right away
                                  </label>
                                </div> */}

                        <div className="py-1">
                          <label
                            htmlFor="coverLetter"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                          >
                            Cover Letter
                          </label>
                          <textarea
                            onBlur={changeFormHandler}
                            type="text"
                            placeholder="Why would you like to join us?"
                            className="resize-y min-h-12 h-32 rounded-md mt-1 text-base focus:ring-dg-primary-900 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white sm:text-sm border-dg-primary-600 block w-full shadow-sm transition duration-200"
                            name="coverLetter"
                            id="coverLetter"
                            value={props.values.coverLetter}
                            onChange={props.handleChange}
                          ></textarea>
                          <div className="h-4">
                            <ErrorMessage
                              name="coverLetter"
                              component="div"
                              className="text-sm text-right text-red-600"
                            />
                          </div>
                        </div>

                        <div className="py-1">
                          <label
                            htmlFor="link"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                          >
                            Website link or Portfolio (optional)
                          </label>
                          <input
                            type="url"
                            name="link"
                            id="link"
                            onBlur={changeFormHandler}
                            value={props.values.link}
                            onChange={props.handleChange}
                            className="mt-1 focus:ring-dg-primary-600 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                          />
                          <div className="h-4">
                            <ErrorMessage
                              name="link"
                              component="div"
                              className="text-sm text-right text-red-600"
                            />
                          </div>
                        </div>

                        <div className="py-1 dark:text-gray-500">
                          <MyDropzone
                            dropzoneToFormData={dropzoneToFormData}
                            props={props}
                            fileInput={fileInput}
                          />
                          <div className="h-4">
                            <div className="text-sm text-right">{errorMsg}</div>
                          </div>
                        </div>
                        <div className="items-center justify-end flex">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            ref={captchaRef}
                          />
                        </div>
                        <div className=" py-3 text-right">
                          <button
                            type="submit"
                            className="btn btn_primary transition-all inline-flex justify-center py-4 px-14 border border-transparent shadow-md text-sm font-semibold rounded-xl text-white bg-dg-primary-600 hover:bg-dg-primary-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dg-primary-600"
                          >
                            {btnText}
                          </button>
                        </div>
                        {sucMsg && (
                          <div className={'text-sm text-right text-dg-primary-900'}>
                            {msgText}
                          </div>
                        )}
                      </div>

                      <div className="col-span-1 sm:col-span-1 lg:col-span-1 mt-8">
                        Or contact us directly via email{' '}
                        <a
                          className="text-semibodld text-dg-primary-600"
                          href="mailto:hr@dilig.net"
                        >
                          hr@dilig.net
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </motion.div>
        <motion.div
          className="mt-5 md:mt-0 md:col-span-1 flex items-center"
          initial={{ x: 60, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 60, opacity: 0 }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
        >
          <img
            src={api_url + props.img.data.attributes.url}
            alt={props.img.data.attributes.alternativeText}
          />
        </motion.div>
      </div>
    </div>
  );
}

JobForm.propTypes = {
  defaultPositionSelection: propTypes.string,
};
