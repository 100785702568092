import { motion } from 'framer-motion';
import { PropTypes } from 'prop-types';

import '../../../styles/graphics.css';
//import { useState, useEffect } from 'react';

const transition = { type: 'tween' };

export default function HoverServices({ circle, hex, triangle }) {
  return (
    <div className="relative">
      <svg
        width="722"
        height="464"
        viewBox="0 0 722 464"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M660.196 283.199C549.573 407.446 326.014 485.168 160.859 456.789C-4.29616 428.411 -48.4933 304.679 62.1291 180.432C172.751 56.1849 396.311 -21.5374 561.466 6.84142C726.621 35.2202 770.818 158.952 660.196 283.199Z"
          stroke="#BBBBBB"
        />
      </svg>

      <motion.div
        className="circle-hover"
        initial={{ offsetDistance: `${circle}%` }}
        animate={{ offsetDistance: `${circle}%` }}
        transition={transition}
        //style={{ offsetDistance: `${currentPrecentCircle}%` }}
      >
        <svg
          width="47"
          height="47"
          viewBox="0 0 47 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.543 46.2172C37.2256 45.505 46.9295 34.6464 46.2172 21.9638C45.505 9.28124 34.6464 -0.422636 21.9638 0.289591C9.28125 1.00182 -0.422625 11.8604 0.289602 24.543C1.00183 37.2256 11.8605 46.9295 24.543 46.2172Z"
            fill="#8468BF"
          />
        </svg>
      </motion.div>
      <motion.div
        className="triangle-hover"
        initial={{ offsetDistance: `${triangle}%` }}
        animate={{ offsetDistance: `${triangle}%` }}
        transition={transition}
      >
        <svg
          width="51"
          height="49"
          viewBox="0 0 51 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5718 8.39674C-0.361545 5.8152 1.39846 2.1245 4.62137 2.00186L46.5211 0.407439C49.3163 0.301072 51.3547 3.02316 50.4659 5.67545L37.1447 45.4298C36.12 48.4878 32.0835 49.1381 30.1503 46.5567L1.5718 8.39674Z"
            fill="#FFA463"
          />
        </svg>
      </motion.div>
      <motion.div
        className="hex-hover"
        initial={{ offsetDistance: `${hex}%` }}
        animate={{ offsetDistance: `${hex}%` }}
        transition={transition}
      >
        <svg
          width="49"
          height="52"
          viewBox="0 0 49 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M45.7012 11.4816C47.5931 12.6299 48.6993 14.7264 48.5792 16.9363L47.5127 36.5626C47.3983 38.6685 46.1867 40.5596 44.3214 41.5437L26.1071 51.154C24.2418 52.1381 21.9969 52.0708 20.194 50.9765L3.39148 40.7783C1.49954 39.63 0.393351 37.5335 0.513441 35.3236L1.57997 15.6973C1.69441 13.5915 2.90601 11.7003 4.77123 10.7162L22.9856 1.10591C24.8508 0.121778 27.0958 0.189158 28.8986 1.28338L45.7012 11.4816Z"
            fill="#BDC4FF"
          />
        </svg>
      </motion.div>
    </div>
  );
}

HoverServices.propTypes = {
  circle: PropTypes.number,
  hex: PropTypes.number,
  triangle: PropTypes.number,
};
