import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from './../../utils/formatDate';
import CategoryNugget from './CategoryNugget';

const api_url = process.env.REACT_APP_API_URL;

const ArticleCard = ({ data }) => {
  return (
    <Fragment>
      {data && (
        <a
          className={
            'card box my-2 flex flex-col md:flex-row items-center justify-between gap-[72px]'
          }
          href={`/articles/${data.Slug}`}
        >
          <div className="flex flex-col gap-[18px]">
            {data.Author && (
              <div className="flex gap-4">
                <img
                  className="max-h-[45px] object-fit rounded-full"
                  src={api_url + data.AuthorImage.data.attributes.url}
                  alt={data.AuthorImage.data.attributes.alternativeText}
                />
                <div className="flex flex-col items-start">
                  <p className="paragraph">{data.Author}</p>
                  <p className="text-small-subhead text-gray-400 leading-normal text-left">
                    {data.AuthorTitle}
                  </p>
                </div>
              </div>
            )}

            <div className="text-left flex flex-col gap-[8px]">
              <h2 className="n-h3-heading text-dark-gray">{data.ArticleTitle}</h2>
              <p className="n-paragraph w-full md:w-[450px]">{data.ArticleDescription}</p>
            </div>

            <div className="flex gap-4 items-center">
              {data.Date && (
                <p className="text-small-subhead text-gray-400 leading-normal">
                  {formatDate(data.Date)}
                </p>
              )}

              {data.article_category.data && (
                <CategoryNugget data={data.article_category.data.attributes.Name} />
              )}
            </div>
          </div>
          <div className="w-full md:w-[450px] rounded-8 overflow-hidden">
            <img
              src={api_url + data.ArticleImage.data.attributes.url}
              alt={data.ArticleImage.data.attributes.alternativeText}
            />
          </div>
        </a>
      )}
    </Fragment>
  );
};

export default ArticleCard;
