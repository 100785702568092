import { useRef, useState } from 'react';
import { motion } from 'framer-motion';

export default function LandingSVG() {


  //paths
  const hexPath = useRef();
  const circlePath = useRef();
  //hex
  const hexViolet1 = useRef();
  //const hexViolet2 = useRef();
  const hexBlue1 = useRef();
  const hexPurple1 = useRef();

  const trianglePrussian1 = useRef();
  const triangleBlue2 = useRef();
  const triangleViolet1 = useRef();
  const triangleYellow1 = useRef();
  const triangleYellow2 = useRef();
  const triangleYellow3 = useRef();

  const circlePrussian1 = useRef();
  const circlePurple1 = useRef();
  const circlePurple2 = useRef();
  const circleTirq1 = useRef();
  const circleTirq2 = useRef();

  const logoBack = useRef();
  const dots = useRef();
  const logo = useRef();



  const [isHover, setIsHover] = useState(false);

  const transitionOnHover = {
    duration: 0.5,
    type: 'spring',
    stiffness: 150,
    mass: 0.5,
    ease: 'easeOut',
};



  return (
    <motion.svg
      width="900"
      height="550"
      viewBox="-50 -85 900 550"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"

    //whileHover={{ scale: 1.2 }}
    // variants={{
    //     rest: { opacity: 0 },
    //     hover: { opacity: 1 },
    //   }}


      onHoverStart={() => {
        //resetMousePosition();
        setIsHover(true);
      }}
      onTouchStart={() => {
        setIsHover(true);
      }}
      onHoverEnd={() => {
        //resetMousePosition();
        setIsHover(false);
      }}
      onTouchEnd={() => {
        setIsHover(false);
      }}
    >
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.2,
             x: 28.9,
             y: -23.2,
             rotate: 85,
           },
        }}
        transition={transitionOnHover}
        ref={hexPath}
        d="M542.656 145.227L519.062 82.735L519.353 82.374L561.393 31.0271L561.85 31.1059L627.32 41.8488L650.968 104.384L650.677 104.745L608.612 156.026L608.155 155.947L542.656 145.227ZM520.881 83.028L543.797 143.792L607.912 154.305L649.08 104.041L626.133 43.264L562.015 32.84L520.881 83.028Z"
        fill="#BBBBBB"
      />
      <motion.path
    animate={isHover ? 'hover' : 0}
    variants={{
    hover: {
         scale: 1.2,
         x: -20.9,
         y: -23.2,
         rotate: 25,
       },
    }}
    transition={transitionOnHover}
      d="M568.306 60.8356C567.885 58.6628 566.303 56.8981 564.188 56.2443L497.014 35.4714C495 34.8483 492.806 35.3288 491.236 36.7369L437.141 85.2543C435.571 86.6624 434.855 88.7913 435.256 90.8618L448.626 159.891C449.046 162.064 450.629 163.829 452.743 164.483L519.917 185.256C521.932 185.879 524.126 185.398 525.696 183.99L579.791 135.473C581.361 134.065 582.076 131.936 581.675 129.865L568.306 60.8356Z"
      fill="#9A4799"
    />
      <motion.path
      ref={trianglePrussian1}
      animate={isHover ? 'hover' : 0}
      variants={{
      hover: {
           scale: 1.2,
           x: 20.9,
           y: 30.2,
           rotate: 45,
         },
      }}
      transition={transitionOnHover}
        d="M615.745 142.363C618.822 141.398 621.718 144.285 620.762 147.365L613.494 170.779C612.665 173.451 609.42 174.468 607.214 172.748L587.883 157.673C585.34 155.69 586.069 151.667 589.146 150.702L615.745 142.363Z"
        fill="#7274AB"
      />
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.2,
             x: 28.9,
             y: -63.2,
             rotate: 35,
           },
        }}
        transition={transitionOnHover}
        ref={triangleYellow3}
        d="M576.833 68.2988C579.91 67.3339 582.806 70.2211 581.849 73.3013L574.582 96.7153C573.753 99.3868 570.508 100.404 568.302 98.6839L548.971 83.6091C546.428 81.6258 547.157 77.6028 550.234 76.638L576.833 68.2988Z"
        fill="#FFA463"
      />
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.2,
             x: 28.9,
             y: -63.2,
             rotate: 35,
           },
        }}
        transition={transitionOnHover}
        ref={circlePrussian1}
        d="M324.71 43.8595C308.147 43.3846 294.335 56.4269 293.86 72.9903C293.385 89.5536 306.427 103.366 322.991 103.841C339.554 104.316 353.366 91.2732 353.841 74.7098C354.316 58.1465 341.274 44.3343 324.71 43.8595Z"
        fill="#7274AB"
      />
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.2,
             x: -68.9,
             y: 13.2,
           },
        }}
        transition={transitionOnHover}
        ref={circlePath}
        d="M171.226 247.834C171.226 305.875 218.278 352.927 276.319 352.927C334.36 352.927 381.412 305.875 381.412 247.834C381.412 189.793 334.36 142.741 276.319 142.741C218.278 142.741 171.226 189.793 171.226 247.834Z"
        stroke="#BBBBBB"
        strokeWidth="2"
      />
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.2,
             x: 38.9,
             y: -33.2,
             rotate: 25,
           },
        }}
        transition={transitionOnHover}
        ref={hexViolet1}
        d="M274.022 193.903C274.808 195.972 274.392 198.306 272.939 199.975L221.872 258.63C220.487 260.221 218.369 260.967 216.293 260.597L137.21 246.487C135.134 246.117 133.405 244.684 132.656 242.712L105.024 170.016C104.238 167.947 104.654 165.614 106.107 163.944L157.174 105.289C158.559 103.699 160.677 102.952 162.753 103.322L241.836 117.432C243.912 117.802 245.641 119.236 246.39 121.207L274.022 193.903Z"
        fill="#9A4799"
      />
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.2,
             x: -48.9,
             y: 43.2,
             rotate: 45,
           },
        }}
        transition={transitionOnHover}
        ref={hexBlue1}
        d="M252.171 237.952C254.063 239.1 255.169 241.197 255.049 243.407L253.186 277.684C253.072 279.79 251.86 281.681 249.995 282.665L218.441 299.313C216.576 300.298 214.331 300.23 212.528 299.136L183.183 281.325C181.291 280.177 180.185 278.08 180.305 275.87L182.167 241.593C182.282 239.487 183.494 237.596 185.359 236.612L216.912 219.964C218.777 218.98 221.022 219.047 222.825 220.141L252.171 237.952Z"
        fill="#BDC4FF"
      />
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.2,
             x: 38.9,
             y: 33.2,
             rotate: 65,
           },
        }}
        transition={transitionOnHover}
        ref={hexPurple1}
        d="M574.161 208.335C576.053 209.484 577.16 211.58 577.039 213.79L575.177 248.067C575.062 250.173 573.851 252.064 571.986 253.048L540.432 269.696C538.567 270.681 536.322 270.613 534.519 269.519L505.174 251.708C503.282 250.56 502.175 248.463 502.296 246.253L504.158 211.976C504.273 209.87 505.484 207.979 507.349 206.995L538.903 190.347C540.768 189.363 543.013 189.43 544.816 190.524L574.161 208.335Z"
        fill="#8468BF"
      />
      <motion.g ref={dots}
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.6,
             x: 38.9,
             y: 33.2,
           },
        }}
        transition={transitionOnHover}
      >
        <path
          d="M498.056 251.362C498.056 251.736 497.945 252.101 497.738 252.412C497.53 252.722 497.235 252.964 496.889 253.107C496.544 253.25 496.164 253.287 495.797 253.213C495.431 253.14 495.094 252.959 494.831 252.694C494.567 252.43 494.388 252.092 494.316 251.726C494.244 251.359 494.282 250.979 494.426 250.634C494.57 250.289 494.813 249.995 495.125 249.789C495.436 249.582 495.802 249.472 496.176 249.474C496.423 249.474 496.668 249.523 496.897 249.618C497.126 249.713 497.333 249.852 497.508 250.027C497.683 250.203 497.821 250.411 497.915 250.64C498.009 250.869 498.057 251.114 498.056 251.362V251.362Z"
          fill="#353535"
        />
        <path
          d="M448.802 226.959C448.802 227.332 448.692 227.697 448.484 228.008C448.277 228.318 447.982 228.56 447.637 228.703C447.292 228.846 446.912 228.883 446.546 228.81C446.18 228.738 445.843 228.558 445.579 228.294C445.315 228.03 445.135 227.693 445.063 227.327C444.99 226.961 445.027 226.581 445.17 226.236C445.313 225.891 445.555 225.596 445.865 225.389C446.176 225.181 446.541 225.071 446.914 225.071C447.415 225.071 447.895 225.269 448.249 225.624C448.604 225.978 448.802 226.458 448.802 226.959V226.959Z"
          fill="#353535"
        />
        <path
          d="M473.439 226.959C473.439 227.332 473.328 227.697 473.121 228.008C472.913 228.318 472.619 228.56 472.274 228.703C471.929 228.846 471.549 228.883 471.183 228.81C470.817 228.738 470.48 228.558 470.216 228.294C469.952 228.03 469.772 227.693 469.699 227.327C469.627 226.961 469.664 226.581 469.807 226.236C469.95 225.891 470.192 225.596 470.502 225.389C470.813 225.181 471.178 225.071 471.551 225.071C472.052 225.071 472.532 225.269 472.886 225.624C473.24 225.978 473.439 226.458 473.439 226.959Z"
          fill="#353535"
        />
        <path
          d="M498.056 226.959C498.056 227.332 497.945 227.698 497.738 228.008C497.53 228.319 497.235 228.561 496.889 228.704C496.544 228.846 496.164 228.883 495.797 228.81C495.431 228.736 495.094 228.556 494.831 228.291C494.567 228.026 494.388 227.689 494.316 227.322C494.244 226.956 494.282 226.576 494.426 226.231C494.57 225.886 494.813 225.592 495.125 225.385C495.436 225.179 495.802 225.069 496.176 225.071C496.423 225.071 496.668 225.119 496.897 225.214C497.126 225.309 497.333 225.449 497.508 225.624C497.683 225.799 497.821 226.008 497.915 226.237C498.009 226.466 498.057 226.711 498.056 226.959V226.959Z"
          fill="#353535"
        />
        <path
          d="M522.682 226.959C522.682 227.332 522.571 227.697 522.364 228.008C522.156 228.318 521.861 228.56 521.516 228.703C521.171 228.846 520.792 228.883 520.425 228.81C520.059 228.738 519.723 228.558 519.459 228.294C519.195 228.03 519.015 227.693 518.942 227.327C518.869 226.961 518.907 226.581 519.049 226.236C519.192 225.891 519.434 225.596 519.745 225.389C520.055 225.181 520.42 225.071 520.794 225.071C521.295 225.071 521.775 225.269 522.129 225.624C522.483 225.978 522.682 226.458 522.682 226.959V226.959Z"
          fill="#353535"
        />
        <path
          d="M448.802 251.363C448.802 251.736 448.692 252.101 448.484 252.412C448.277 252.722 447.982 252.964 447.637 253.107C447.292 253.25 446.912 253.287 446.546 253.215C446.18 253.142 445.843 252.962 445.579 252.698C445.315 252.434 445.135 252.097 445.063 251.731C444.99 251.365 445.027 250.985 445.17 250.64C445.313 250.295 445.555 250.001 445.865 249.793C446.176 249.586 446.541 249.475 446.914 249.475C447.415 249.475 447.895 249.674 448.249 250.028C448.604 250.382 448.802 250.862 448.802 251.363V251.363Z"
          fill="#353535"
        />
        <path
          d="M473.439 251.364C473.439 251.737 473.328 252.102 473.121 252.413C472.913 252.723 472.619 252.965 472.274 253.108C471.929 253.251 471.549 253.288 471.183 253.216C470.817 253.143 470.48 252.963 470.216 252.699C469.952 252.435 469.772 252.098 469.699 251.732C469.627 251.366 469.664 250.986 469.807 250.641C469.95 250.296 470.192 250.001 470.502 249.794C470.813 249.587 471.178 249.476 471.551 249.476C472.052 249.476 472.532 249.675 472.886 250.029C473.24 250.383 473.439 250.863 473.439 251.364V251.364Z"
          fill="#353535"
        />
        <path
          d="M522.681 251.364C522.681 251.737 522.571 252.102 522.363 252.413C522.156 252.723 521.861 252.965 521.516 253.108C521.171 253.251 520.791 253.288 520.425 253.216C520.059 253.143 519.722 252.963 519.458 252.699C519.194 252.435 519.014 252.098 518.942 251.732C518.869 251.366 518.906 250.986 519.049 250.641C519.192 250.296 519.434 250.001 519.744 249.794C520.055 249.587 520.42 249.476 520.793 249.476C521.294 249.476 521.774 249.675 522.128 250.029C522.482 250.383 522.681 250.863 522.681 251.364V251.364Z"
          fill="#353535"
        />
        <path
          d="M448.802 275.769C448.802 276.143 448.692 276.508 448.484 276.818C448.277 277.129 447.982 277.371 447.637 277.513C447.292 277.656 446.912 277.694 446.546 277.621C446.18 277.548 445.843 277.368 445.579 277.104C445.315 276.84 445.135 276.504 445.063 276.137C444.99 275.771 445.027 275.392 445.17 275.047C445.313 274.702 445.555 274.407 445.865 274.199C446.176 273.992 446.541 273.881 446.914 273.881C447.415 273.881 447.895 274.08 448.249 274.434C448.604 274.788 448.802 275.268 448.802 275.769V275.769Z"
          fill="#353535"
        />
        <path
          d="M473.439 275.768C473.439 276.142 473.328 276.507 473.121 276.817C472.913 277.128 472.619 277.37 472.274 277.512C471.929 277.655 471.549 277.693 471.183 277.62C470.817 277.547 470.48 277.367 470.216 277.103C469.952 276.839 469.772 276.503 469.699 276.136C469.627 275.77 469.664 275.391 469.807 275.046C469.95 274.701 470.192 274.406 470.502 274.198C470.813 273.991 471.178 273.88 471.551 273.88C472.052 273.88 472.532 274.079 472.886 274.433C473.24 274.787 473.439 275.267 473.439 275.768Z"
          fill="#353535"
        />
        <path
          d="M498.057 275.768C498.057 276.142 497.946 276.507 497.738 276.818C497.53 277.128 497.235 277.37 496.89 277.513C496.544 277.656 496.164 277.693 495.798 277.619C495.431 277.546 495.095 277.365 494.831 277.101C494.567 276.836 494.388 276.499 494.316 276.132C494.244 275.765 494.283 275.385 494.427 275.041C494.571 274.696 494.814 274.401 495.125 274.195C495.437 273.988 495.802 273.879 496.176 273.88C496.424 273.88 496.669 273.929 496.898 274.024C497.126 274.119 497.334 274.258 497.509 274.434C497.683 274.609 497.822 274.817 497.916 275.046C498.01 275.275 498.058 275.521 498.057 275.768V275.768Z"
          fill="#353535"
        />
        <path
          d="M522.681 275.769C522.681 276.143 522.571 276.508 522.363 276.818C522.156 277.129 521.861 277.371 521.516 277.513C521.171 277.656 520.791 277.694 520.425 277.621C520.059 277.548 519.722 277.368 519.458 277.104C519.194 276.84 519.014 276.504 518.942 276.137C518.869 275.771 518.906 275.392 519.049 275.047C519.192 274.702 519.434 274.407 519.744 274.199C520.055 273.992 520.42 273.881 520.793 273.881C521.294 273.881 521.774 274.08 522.128 274.434C522.482 274.788 522.681 275.268 522.681 275.769V275.769Z"
          fill="#353535"
        />
        <path
          d="M448.802 300.171C448.802 300.545 448.692 300.91 448.484 301.22C448.277 301.531 447.982 301.773 447.637 301.916C447.292 302.059 446.912 302.096 446.546 302.023C446.18 301.95 445.843 301.771 445.579 301.507C445.315 301.242 445.135 300.906 445.063 300.54C444.99 300.174 445.027 299.794 445.17 299.449C445.313 299.104 445.555 298.809 445.865 298.602C446.176 298.394 446.541 298.283 446.914 298.283C447.415 298.283 447.895 298.482 448.249 298.836C448.604 299.191 448.802 299.671 448.802 300.171V300.171Z"
          fill="#353535"
        />
        <path
          d="M473.439 300.171C473.439 300.545 473.328 300.91 473.121 301.22C472.913 301.531 472.619 301.773 472.274 301.916C471.929 302.059 471.549 302.096 471.183 302.023C470.817 301.95 470.48 301.771 470.216 301.507C469.952 301.242 469.772 300.906 469.699 300.54C469.627 300.174 469.664 299.794 469.807 299.449C469.95 299.104 470.192 298.809 470.502 298.602C470.813 298.394 471.178 298.283 471.551 298.283C472.052 298.283 472.532 298.482 472.886 298.836C473.24 299.191 473.439 299.671 473.439 300.171Z"
          fill="#353535"
        />
        <path
          d="M498.057 300.171C498.057 300.545 497.946 300.911 497.738 301.221C497.53 301.532 497.235 301.774 496.89 301.916C496.544 302.059 496.164 302.096 495.798 302.023C495.431 301.949 495.095 301.769 494.831 301.504C494.567 301.239 494.388 300.902 494.316 300.535C494.244 300.169 494.283 299.789 494.427 299.444C494.571 299.099 494.814 298.805 495.125 298.598C495.437 298.392 495.802 298.282 496.176 298.283C496.424 298.283 496.669 298.332 496.898 298.427C497.126 298.522 497.334 298.661 497.509 298.837C497.683 299.012 497.822 299.221 497.916 299.45C498.01 299.679 498.058 299.924 498.057 300.171Z"
          fill="#353535"
        />
        <path
          d="M522.681 300.17C522.681 300.544 522.571 300.909 522.363 301.219C522.156 301.53 521.861 301.772 521.516 301.915C521.171 302.058 520.791 302.095 520.425 302.022C520.059 301.949 519.722 301.77 519.458 301.506C519.194 301.242 519.014 300.905 518.942 300.539C518.869 300.173 518.906 299.793 519.049 299.448C519.192 299.103 519.434 298.808 519.744 298.601C520.055 298.393 520.42 298.282 520.793 298.282C521.294 298.282 521.774 298.481 522.128 298.835C522.482 299.19 522.681 299.67 522.681 300.17V300.17Z"
          fill="#353535"
        />
      </motion.g>
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.2,
             x: 28.9,
             y: 13.2,
             rotate: 20,
           },
        }}
        transition={transitionOnHover}
        ref={logoBack}
        d="M428.336 307.126C435.615 309.775 443.762 308.202 449.531 303.033L532.365 228.818C537.862 223.893 540.37 216.443 538.97 209.195L517.033 95.6068C515.633 88.3595 510.531 82.3791 503.595 79.855L399.083 41.8228C391.804 39.174 383.657 40.7473 377.888 45.9162L295.054 120.131C289.557 125.056 287.049 132.506 288.448 139.754L310.386 253.342C311.786 260.59 316.888 266.57 323.824 269.094L428.336 307.126Z"
        fill="#BDC4FF"
      />
      <motion.g filter="url(#filter0_d_366_178)" ref={logo} animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.2,
             x: 28.9,
             y: 13.2,
             rotate: 20,
           },
        }}
        transition={transitionOnHover} >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M398.287 181.142C399.934 179.671 399.576 177.818 397.5 177.066L311.825 146.269C309.749 145.517 308.367 146.745 308.796 148.968L326.425 240.25C326.855 242.473 328.595 243.098 330.242 241.627L398.287 181.142Z"
          fill="#90278F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M352.1 97.3635C350.453 98.8347 349.429 101.915 349.858 104.139L358.649 149.656C359.079 151.879 361.165 154.295 363.241 155.047L409.288 171.587C411.364 172.34 412.747 171.112 412.317 168.888L403.479 123.124C403.049 120.901 404.432 119.673 406.508 120.425L452.363 136.938C454.439 137.69 456.526 140.106 456.955 142.329L466.39 191.182C466.819 193.405 465.783 196.424 464.136 197.895L427.739 230.358C426.093 231.829 424.353 231.204 423.923 228.981L415.085 183.217C414.655 180.993 412.915 180.368 411.268 181.839L374.752 214.325C373.105 215.796 372.068 218.815 372.498 221.039L381.289 266.556C381.718 268.779 383.744 271.207 385.892 272.009L431.987 288.796C434.064 289.548 437.126 288.957 438.773 287.486L517.828 217.251C519.475 215.78 520.511 212.761 520.082 210.537L499.59 104.434C499.16 102.21 497.074 99.7945 494.998 99.0424L395.475 63.297C393.398 62.5448 390.336 63.1363 388.689 64.6075L352.1 97.3635Z"
          fill="#353535"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M311.394 136.94C309.318 136.188 308.972 134.397 310.607 132.864L338.145 108.391C339.792 106.92 341.532 107.544 341.961 109.768L349.094 146.7C349.524 148.924 348.141 150.152 346.065 149.399L311.394 136.94Z"
          fill="#90278F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M334.062 254.345C331.986 253.592 331.64 251.801 333.275 250.268L360.813 225.795C362.46 224.324 364.2 224.949 364.629 227.172L371.762 264.105C372.192 266.328 370.809 267.556 368.733 266.804L334.062 254.345Z"
          fill="#90278F"
        />
      </motion.g>
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.3,
             x: 58.9,
             y: -23.2,
           },
        }}
        transition={transitionOnHover}
        ref={circlePurple2}
        d="M684 162C700.569 162 714 148.568 714 132C714 115.431 700.569 102 684 102C667.431 102 654 115.431 654 132C654 148.568 667.431 162 684 162Z"
        fill="#8468BF"
      />
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.2,
             x: -48.9,
             y: -43.2,
           },
        }}
        transition={transitionOnHover}
        ref={circlePurple1}
        d="M98.8463 235.692C122.51 235.692 141.693 216.509 141.693 192.846C141.693 169.182 122.51 149.999 98.8463 149.999C75.1829 149.999 56 169.182 56 192.846C56 216.509 75.1829 235.692 98.8463 235.692Z"
        fill="#8468BF"
      />
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.2,
             x: 8.9,
             y: 23.2,
             rotate: 60,
           },
        }}
        transition={transitionOnHover}
        ref={circleTirq1}
        d="M292.699 369.4C307.445 369.4 319.399 357.446 319.399 342.7C319.399 327.954 307.445 316 292.699 316C277.953 316 265.999 327.954 265.999 342.7C265.999 357.446 277.953 369.4 292.699 369.4Z"
        fill="#B9CFE4"
      />
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.2,
             x: 68.9,
             y: 3.2,
           },
        }}
        transition={transitionOnHover}
        ref={circleTirq2}
        d="M613.5 44.9998C623.717 44.9998 632 36.717 632 26.4998C632 16.2825 623.717 7.99976 613.5 7.99976C603.283 7.99976 595 16.2825 595 26.4998C595 36.717 603.283 44.9998 613.5 44.9998Z"
        fill="#B9CFE4"
      />
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.5,
             x: 68.9,
             y: 83.2,
             rotate: 70,
           },
        }}
        transition={transitionOnHover}
        ref={triangleViolet1}
        d="M531.685 126.528C533.989 127.265 534.519 130.285 532.603 131.762L496.739 159.409C495.077 160.69 492.648 159.914 492.037 157.907L478.856 114.587C478.152 112.273 480.336 110.12 482.64 110.856L531.685 126.528Z"
        fill="#90278F"
      />
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 1.4,
             x: -128.9,
             y: -143.2,
             rotate: 90,
           },
        }}
        transition={transitionOnHover}
        ref={triangleYellow2}
        d="M348.101 284.604C346.028 283.358 346.205 280.296 348.409 279.299L389.664 260.627C391.575 259.762 393.761 261.075 393.895 263.169L396.775 308.358C396.929 310.772 394.31 312.366 392.236 311.12L348.101 284.604Z"
        fill="#FFA463"
      />
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 2.3,
             x: -28.9,
             y: 43.2,
             rotate: 160,
           },
        }}
        transition={transitionOnHover}
        ref={triangleBlue2}
        d="M739.376 197.804C737.303 196.558 737.48 193.497 739.684 192.499L755.114 185.516C757.025 184.651 759.212 185.964 759.345 188.058L760.422 204.959C760.576 207.373 757.957 208.967 755.883 207.721L739.376 197.804Z"
        fill="#BDC4FF"
      />
      <motion.path
        animate={isHover ? 'hover' : 0}
        variants={{
        hover: {
             scale: 2.3,
             x: -18.9,
             y: 123.2,
             rotate: 160,
           },
        }}
        transition={transitionOnHover}
        ref={triangleYellow1}
        d="M22.9844 152.87C21.7348 154.941 18.6737 154.757 17.6804 152.552L10.135 135.798C9.27347 133.885 10.5912 131.701 12.6851 131.572L31.0233 130.437C33.4374 130.288 35.0268 132.91 33.7772 134.981L22.9844 152.87Z"
        fill="#FFA463"
      />
      <defs>
        <filter
          id="filter0_d_366_178"
          x="276.718"
          y="59.9702"
          width="243.461"
          height="258.153"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-16" dy="13" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_366_178"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_366_178"
            result="shape"
          />
        </filter>
      </defs>
    </motion.svg>
  );
}
