import React, { useEffect, useState, useContext } from 'react';
import Contact from '../components/shared/Contact';
import { UIContext } from '../context';
import PageLayout from '../layout/PageLayout';
import useAnalytics from '../hooks/useAnalytics';
import useDataApi from './../hooks/useDataApi';
import ReactHelmet from './../components/shared/ReactHelmet';
import { strapiApiBuilder } from './../utils/strapiApiBuilder';

const api_url = process.env.REACT_APP_API_URL;

const strapiPopulate = [
  'BusinessInquiry',
  'BusinessInquiry.image',
  'ApplyPosition',
  'ApplyPosition.image',
  'SEO',
  'SEO.metaSocial',
  'SEO.metaImage',
  'SEO.metaSocial.image',
];

function ContactPage({tab, position}) {

  const strapi = strapiApiBuilder('w-home-page', strapiPopulate, '');

  const [{ data, isLoading, isError }, doFetch] = useDataApi(strapi);


  useEffect(() => {
    document.title = 'Contact Us';
  }, []);

  useAnalytics('Contact Us');

  return (
    <PageLayout>
      {/* {data.SEO ? <ReactHelmet seo={data.SEO} /> : null} */}
      <section id="contact" className="h-fit bg-baby-blue dark:bg-dg-primary-1600 mt-90p">
          {data ?
          <Contact
            defaultIndex={tab}
            position={position || ''}
            job={data.ApplyPosition}
            business={data.BusinessInquiry}
            /> : null
          }
      </section>
    </PageLayout>
  );
}

export default ContactPage;
