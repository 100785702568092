import React from "react";
import bg from '../../assets/ProcessCardsBG.svg'

const ProcessCardsWrapper = ({children}) => {
    return (
        <div className="relative flex flex-col gap-72p md:gap-140p my-32p md:my-0">
            <div className="hidden lg:block absolute h-full top-0 left-4 right-4 mt-140p mb-140p overflow-hidden">
                <img className="mx-auto h-[90%]" src={bg} alt="process-background" />
            </div>
            {children}
        </div>
    )
}

export default ProcessCardsWrapper;