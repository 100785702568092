import React from 'react';

import { motion } from 'framer-motion';
import OrbitOnScroll from './shared/graphics/OrbitOnScroll';
import PageHeading from './shared/PageHeading';
import WhyUsCard from './WhyUsCard';

import why1 from './../assets/icons/why-us-1.svg';
import why2 from './../assets/icons/why-us-2.svg';
import why3 from './../assets/icons/why-us-3.svg';

import Wrapper from '../layout/Wrapper';

const api_url = process.env.REACT_APP_API_URL;

const _data = {
  heading: {
    subheading: 'Why us',
    heading: 'Dedicated and Long-lasting Partnerships',
    paragraph1:
      'Committed and loyal we build long-lasting partnerships that go beyond technology. We believe that the key to a prosperous partnership is empathy and understanding.',
    paragraph2:
      'We think that our success is directly related to the success of our clients, so we are committed to assisting them in achieving their business goals. After over 15 years of software development, we are convinced that solution is there. With our purpose, everyone at Diligent has a clear and shared understanding of why we do business and what influence we intend to make as a company on business and society.',
  },
  card_left: {
    imgUrl: why1,
    alt: 'Innovative Solutions',
    heading: 'Innovative Solutions',
    paragraph:
      'Technology is just one aspect of our client relationships. Enthusiasts to the core, we bring innovative and real-life solutions to each client’s problems through a deep understanding of their market, approach, and vision.',
  },
  card_mid: {
    imgUrl: why2,
    alt: 'Product Discovery',
    heading: 'Product Discovery',
    paragraph:
      'An idea solves a problem. We help you to create that idea, build a product and scale it to be successful in your business. We develop a roadmap to make sure the development is ready to begin constructing the MVP.',
  },
  card_right: {
    imgUrl: why3,
    alt: 'End-user Engagement',
    heading: 'End-user Engagement',
    paragraph:
      'Our company helps clients to create a successful product. We focus on technical quality to ensure that products are up to standards and meet the needs of users. Innovation is key in developing products that are unique and valuable.',
  },
};

const WhySection = ({ heading,cards,p1,p2 }) => {
  return (
    <Wrapper bg hideOverflow>
      <div className="relative">
        <div className="relative h-[1px] w-full m-auto">
          <div
            className="absolute hidden lg:block"
            id="test"
            style={{ top: '800px', marginLeft: '-200px' }}
          >
            <OrbitOnScroll />
          </div>
        </div>

        <motion.section
          id="why_us"
          className=" flex flex-col items-center relative pt-64p lg:pt-164p pb-[144px]"
        >
          <div className="flex flex-col justify-center items-center gap-90p">
            <div className="max-w-[780px]">
              <PageHeading
                subheading={heading.subtitle}
                heading={heading.title}
                paragraph1={p1}
                paragraph2={p2}
              />
            </div>

            <div className="flex flex-col lg:flex-row gap-32p h-fit sm:px-64p lg:px-0">
              {/* Card - left */}
              <motion.div
                initial={{ opacity: 0, scale: 1.1, y: 90 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ delay: 0, default: { duration: 0.45 } }}
              >
                <WhyUsCard
                  image={cards.Card1.Image.data.attributes.url}
                  alt={cards.Card1.Image.data.attributes.alternativeText}
                  heading={cards.Card1.Title}
                  paragraph={cards.Card1.paragraph}
                />
              </motion.div>
              {/* Card - mid */}
              <motion.div
                initial={{ opacity: 0, scale: 1.1, y: 120 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ delay: 0.27, default: { duration: 0.45 } }}
              >
                <WhyUsCard
                  image={cards.Card2.Image.data.attributes.url}
                  alt={cards.Card2.Image.data.attributes.alternativeText}
                  heading={cards.Card2.Title}
                  paragraph={cards.Card2.paragraph}
                />
              </motion.div>
              {/* Card - right */}
              <motion.div
                initial={{ opacity: 0, scale: 1.1, y: 140 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ delay: 0.54, default: { duration: 0.45 } }}
              >
                <WhyUsCard
                  image={cards.Card3.Image.data.attributes.url}
                  alt={cards.Card3.Image.data.attributes.alternativeText}
                  heading={cards.Card3.Title}
                  paragraph={cards.Card3.paragraph}
                />
              </motion.div>
            </div>
          </div>
        </motion.section>
      </div>
    </Wrapper>
  );
};

export default WhySection;
