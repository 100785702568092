import React, { useEffect, lazy, Suspense, useRef, useState, useContext } from 'react';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Navigation from './components/shared/Navigation';
import CookieBanner from './components/shared/CookieBanner';
import Footer from './components/shared/Footer';
import Loader from './components/shared/Loader';
import ScrollToTop from './components/root/ScrollToTop';
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';
import routes from './routes';
import UIContextProvider from './context/UIContextProvider';

const Home = lazy(() => import('./pages/Home'));
const Portfolio = lazy(() => import('./pages/Portfolio'));
const Services = lazy(() => import('./pages/Services'));
const Careers = lazy(() => import('./pages/Careers'));
const Culture = lazy(() => import('./pages/Culture'));

const About = lazy(() => import('./pages/About'));
const Blog = lazy(() => import('./pages/Blog'));
const ProcessPage = lazy(() => import('./pages/ProcessPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const WorkWithUs = lazy(() => import('./pages/WorkWithUs'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const ArticlePage = lazy(() => import('./pages/ArticlePage'));
const CaseStudyPage = lazy(() => import('./pages/CaseStudyPage'));

// Navigation Links
const links = routes.filter(item => item.nav === true);

function App() {
  //tabs for contact form
  const link = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const [diligentCookie, setDiligentCookie] = useState(cookies.diligent_ga);
  //scroll to Contact segment
  const forwardedRef = useRef(null);


  useEffect(() => {
    if (diligentCookie === 'true') {
      ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
    }
  }, [diligentCookie]);

  //scroll to Contact fn
  function scrollToView(event) {
    event.preventDefault();
    if (forwardedRef) {
      window.scrollTo({ behavior: 'smooth', top: forwardedRef.current.offsetTop });
    } else {
      link('/contact');
    }
  }

  //nav active links
  function activeLinks(activePage, bool = true) {
    const text = 'text-gray-500 ';
    if (bool === true) {
      return activePage ? 'activeLink dg-primary-900 ' : text;
    } else {
      return text;
    }
  }

  const handleAccept = () => {
    setCookie('diligent_ga', 'true', { path: '/' });
    setDiligentCookie(prevValue => !prevValue);
  };

  const handleDecline = () => {
    setCookie('diligent_ga', 'false', { path: '/' });
    setDiligentCookie(prevValue => !prevValue);
  };

  return (
    <div>
      <ScrollToTop />
      <UIContextProvider>
        <Navigation
          links={links}
          scrollToView={scrollToView}
          activeLinks={activeLinks}
          forwardedRef={forwardedRef}
        ></Navigation>
        {diligentCookie === undefined && (
          <CookieBanner handleAccept={handleAccept} handleDecline={handleDecline} />
        )}
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="*" element={<NotFoundPage />} />
            <Route exact path="/" element={<Home forwardedRef={forwardedRef} />} />
            <Route exact path="/workwithus" element={<WorkWithUs />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route exact path="/case-studies/:slug" element={<CaseStudyPage />} />
            <Route exact path="/process" element={<ProcessPage />} />
            <Route exact path="/careers" element={<Careers />} />
            <Route exact path="/culture" element={<Culture />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/blog" element={<Blog />}/>
            <Route exact path="/articles/:slug" element={<ArticlePage />} />
            <Route exact path="/contact" element={<ContactPage />} />
            <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          </Routes>
        </Suspense>

        <Footer
          links={links}
          activeLinks={activeLinks}
          scrollToView={scrollToView}
          forwardedRef={forwardedRef}
        />
      </UIContextProvider>
    </div>
  );
}

export default App;
