import React from 'react';
import useFetchCollections from '../hooks/useFetchCollections';
import { strapiApiBuilder } from '../utils/strapiApiBuilder';
import Animation_Diligent from '../assets/animation_diligent.webm';
import PortfolioCard from '../components/PortfolioCard';
import IrregularPortfolioGrid from '../layout/IrregularPortfolioGrid';
import PortfolioCardNew from '../components/shared/PortfolioCardNew';

const api_url = process.env.REACT_APP_API_URL;

const strapiPopulate = [
  'Slug',
  'Heading',
  'Heading.paragraphs',
  'BackgroundImage',
];

const PortfolioDataLayer = ({filter}) => {
  const strapi = strapiApiBuilder('case-studies', strapiPopulate, filter ? filter : '');

  const [{ data, isLoading, isError }, doFetch] = useFetchCollections(strapi);


  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  }

  return (
    <>
      <IrregularPortfolioGrid>
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <PortfolioCardNew key={index} data={item.attributes} />
          ))}
      </IrregularPortfolioGrid>
    </>
  );
};

export default PortfolioDataLayer;
