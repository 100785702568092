import React from "react";
import PropTypes from 'prop-types'

const PageTitleOneFont = ({heading, subheading, left, color, pb }) => {
    return (
        <div className={"flex flex-col gap-8p"+ (left ? " text-left items-start":" text-center items-center") + (color ? " text-[#9B32CE]" : " ") + (pb ? ' pb-2' :' ')}>
            <h6 className={"n-subheading" + (left ? " text-left":" text-center")}>{subheading}</h6>
            <h1 className={"n-heading" + (left ? " text-left":" text-center")}>{heading}</h1>
        </div>
    )
}

PageTitleOneFont.propTypes = {
    heading: PropTypes.string,
    subheading: PropTypes.string,
    left: PropTypes.bool,
    color: PropTypes.bool,
}

export default PageTitleOneFont;

