import React from 'react'
import back from './../../assets/SliderWrapBack.svg';
import front from './../../assets/SliderWrapFront.svg';


const SliderWrapper = ({children}) => {
  return (
    <div className='relative'>
    <div className='absolute z-0 top-0 left-4 bottom-0 right-0'>
        <img src={back} alt="" />
    </div>
    <div className='relative h-[280.64px] w-[1022px]'>
        {children}
    </div>
    <div className='hidden absolute z-20 top-0 left-0 bottom-0 right-0'>
        <img src={front} alt="" />
    </div>
    </div>
  )
}

export default SliderWrapper