import React, { useEffect } from 'react';
//import useAnalytics from '../hooks/useAnalytics';
import Wrapper from '../layout/Wrapper';

const PrivacyPolicy = () => {

  useEffect(() => {
    document.title = 'Privacy Policy';
  }, []);

  //useAnalytics();


  return (
    <div className="mt-32p">
      <Wrapper padding={' py-90p'}>
        <h3 className="font-semibold text-title pb-4">Diligent d.o.o Privacy and policy</h3>
        <p className="pb-2">Diligent d.o.o. is committed to complying with data protection legislation, including the data protection regime introduced by the General Data Protection Regulation (EU Regulation 2016/679). This privacy notice contains information on what personal data we collect, what we do with that information, and what rights you have.</p>
        <p className="pb-2">We process some personal data in order to run our business. There are a limited number of circumstances where we may share your information with third parties (for example, pursuant to a court order, if we are part of a merger, or with our business partners and service providers who support our business or collaborate with us). We take the security of your information seriously and take steps to keep your information secure. Please read the full Privacy and Security Statement below and you can always contact us if you have questions.</p>
        <br/>
        <h4 className="font-semibold text-title-24 pb-4">Introducing</h4>
        <p className="pb-2">We respect your privacy and are committed to protecting it through our compliance with this Privacy and Security Statement. This Privacy and Security Statement (together with our Terms of Use) describes the types of information we may collect from you or that you may provide—for example, when you visit any of our websites or contact us—and how we use, protect, and disclose that information.</p>
        <p className="pb-2">This Privacy and Security Statement does not apply to information collected on any third-party site or application (including advertising) that may link to or be accessible from our websites. We are not responsible for the privacy policies or data collection, use and disclosure practices of those sites. We encourage you to review the privacy policies of each site you visit.</p>
        <p className="pb-2">Please read this Privacy and Security Statement carefully to understand our policies and practices regarding your information and how we will treat it. By accessing or using any of our websites or services (including email communications), you agree to this Privacy and Security Statement. This Statement may change from time to time, and these changes may affect how we use the information described below, so please check the Privacy and Security Statement periodically for updates.</p>
        <br/>
        <h4 className="font-semibold text-title-24 pb-4">How we use and protect personal information</h4>
        <p className="pb-2">We collect several types of information for our business operations, including but not limited to:</p>
        <p className="pb-2">Information you provide to us. When you navigate our websites or contact us, we may request or you may choose to provide us with certain information. This may include information by which you may be personally identified (“personal information”), such as name, employer, e-mail address or telephone number, and records and copies of your correspondence with us. For security purposes, we may also collect personal information from you if you visit one of our offices.</p>
        <p className="pb-2">Information collected from forms on our websites. This includes information provided at the time of registering to use portions of our websites or our products and services, posting material, or requesting further services. We may ask you for information when you report a problem with our websites, products or services.</p>
        <p className="pb-2">Usage details, IP addresses and cookies. As you navigate through and interact with our websites, we may automatically collect certain information about your equipment, browsing actions and patterns using common internet technologies, such as cookies and Web beacons. This may include details of your visits to our websites, including information about your connectivity, such as your IP address and browser information, location data, logs and other communication data, and the resources that you access and use on the websites. This information helps us to improve our websites and to deliver better and more personalized content and services by enabling us to estimate our audience size and usage patterns and recognize you when you return to our websites.
        </p>
        <p className="pb-2">General business-related information. We may also receive other personal information from or about you in the ordinary course of our business.</p>
        <p className="pb-2">We use information that we collect about you or that you provide to us, including personal information:</p>
        <ul className="ml-6">
          <li className="list-disc">To present our websites, products and services to you and continuously improve upon them.</li>
          <li className="list-disc">To provide you with information, products or services that you request from us.</li>
          <li className="list-disc">To notify you about changes to our website, products or services (including new offerings) and promotions or events.</li>
          <li className="list-disc">To maintain the integrity and security of our websites, products, and services.</li>
          <li className="list-disc">For our ordinary business operations, including human resources, recruiting, and business research and outreach.</li>
        </ul>

        <p className="pb-2">Legal basis for the processing. We are not allowed to process personal data if we do not have a valid legal ground. Therefore, we will only process your personal data if:</p>
        <ul className="ml-6">
          <li className="list-disc">The processing is necessary to perform our contractual obligations towards you or to take pre-contractual steps at your request, such as when we authenticate your log-in to our services or ensure that you only have access to the services and data we have agreed to provide you with;</li>
          <li className="list-disc">The processing is necessary to comply with our legal or regulatory obligations, such as tax reporting or regulatory requirements;</li>
          <li className="list-disc">The processing is necessary for the legitimate interests of Diligent d.o.o. and does not unduly affect your interests or fundamental rights and freedoms (see below);</li>
          <li className="list-disc">the processing is necessary for the performance of a task carried out in the public interest; or in some cases, and as may be requested from you from time to time, we have obtained prior consent.
          </li>
            
        </ul>
        <p className="pb-2">To the extent that we process any special categories of data relating to you, we will do so because:</p>
        <ul className="ml-6">
          <li className="list-disc">the data has been manifestly made public;</li>
          <li className="list-disc">the processing is necessary for the establishment, exercise or defense of a legal claim;</li>
          <li className="list-disc">the processing is necessary for reasons of 
            substantial public interest; or you have given your explicit consent
            to us to process that information (where legally permissible).
          </li>
        </ul>
        <br/>
        <h4 className="font-semibold text-title-24 pb-4">Disclosure of your information</h4>
        <p className="pb-2">Protecting your personal information is important to us and we neither rent nor sell your personal information to anyone. We may disclose or transfer personal information that we collect or you provide as described in this Privacy and Security Statement, including:</p>
        <ul className="ml-6">
          <li className="list-disc">To a buyer or other successor in the event of a merger, acquisition, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of Diligent d.o.o. assets, in which personal information held by Diligent d.o.o. about our websites’ users is among the assets transferred.</li>
          <li className="list-disc">To our subsidiaries, affiliates, agents, contractors, service providers and other third parties we use to support our business or collaborate with and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes of providing services for or with us.</li>
          <li className="list-disc">To comply with any court order, law or legal process, including to respond to any government or regulatory request.</li>
          <li className="list-disc">To enforce or apply our Terms of Use and other agreements that govern the sale or use of our products and services.</li>
          <li className="list-disc">To protect the rights, property or security of Diligent d.o.o. our employees, our users or others.</li>
        </ul>
        <br/>
        <h4 className="font-semibold text-title-24 pb-4">Onward transfer of your information</h4>
        <p className="pb-2">To facilitate our global operations, and in accordance with applicable laws, we may transfer your personal information to, and access your personal information from, our corporate offices, subsidiaries, and affiliates in the various countries in which we operate.</p>
        <p className="pb-2">You may request additional information in this respect and obtain a copy of the relevant safeguard by exercising your rights as set out below. Where Diligent d.o.o. transfers personal data to other group companies, we rely on the standard contractual clauses.</p>
        <br/>
        <h4 className="font-semibold text-title-24 pb-4">Choices about how we use and disclose your information</h4>
        <p className="pb-2">We strive to provide you with choices regarding the personal information you provide to us. Here are a couple of ways you can have control over your information:</p>
        <p className="pb-2">Tracking Technologies and Advertising. Depending on your browser, you may be able to change settings to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of the site may then be inaccessible or not function properly. </p>
        <br/>
        <h4 className="font-semibold text-title-24 pb-4">Data integrity and proportionality</h4>
        <p>We only collect and retain as much personal information as needed for 
          specific, identified purposes described in this Privacy and Security Policy
          and we will not use it in any way that is incompatible with those purposes.</p>
        <br/>
        <h4 className="font-semibold text-title-24 pb-4">What are your rights and how can you exercise them?</h4>
        <p className="pb-2">You may have a right to access and to obtain a copy of your personal data as processed by Diligent d.o.o. If you believe that any information we hold about you is incorrect or incomplete, you may also request the correction of your personal data.</p>
        <p className="pb-2">You may also have the right to:</p>
        <ul className="ml-6">
          <li className="list-disc">object to the processing of your personal data;</li>
          <li className="list-disc">request the erasure of your personal data;</li>
          <li className="list-disc">request restriction on the processing of your personal data; and/or</li>
          <li className="list-disc">withdraw your consent where Diligent d.o.o. obtained your consent to process personal data (without this withdrawal affecting the lawfulness of any processing that took place prior to the withdrawal).</li>
        </ul>
        <p className="pb-2">We will honor such requests, withdrawal or objection as required under applicable data protection rules but these rights are not absolute: they do not always apply and exemptions may be engaged. We will usually, in response to a request, ask you to verify your identity and/or provide information that helps us to better understand your request. If we do not comply with your request, we will explain why.</p>
        <br/>
        <h3 className="font-semibold text-title-24 pb-4">Exercising your rights</h3>
        <p className="pb-2">To exercise the above rights, you may send an email to office@dilig.net. If you are not satisfied with how we process your personal data, please let us know and we will investigate your concern.</p>
        <br/>
        <h3 className="font-semibold text-title-24 pb-4">Security</h3>
        <p className="pb-2">We take your security seriously and take reasonable steps to protect and secure your personal information from unauthorized access, use, and disclosure. We have implemented adequate technical and organizational measures to protect personal data against unauthorized, accidental or unlawful destruction, loss, alteration, misuse, disclosure or access and against all other unlawful forms of processing. These security measures have been implemented taking into account the state of the art of the technology, their cost of implementation, the risks presented by the processing and the nature of the personal data, with particular care for sensitive data.</p>
        <p className="pb-2">The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our websites, products, or services, you are responsible for keeping this password confidential. Please do not share your password with anyone.</p>
        <p className="pb-2">The transmission of information via the internet is not 100% secure and we cannot ensure or warrant the security of any information you transmit to us. We are not responsible for circumvention of any privacy settings or security measures contained on the websites.</p>
        <p className="pb-2">If you are not satisfied with our response, you have the right to make a complaint to the data protection authority in the jurisdiction where you live or work, or in the place where you think an issue in relation to your data has arisen.</p>
        <br/>
        <h3 className="font-semibold text-title-24 pb-4">How long do we store your data?</h3>
        <p className="pb-2">We will only retain personal data for as long as necessary to fulfil the purpose for which it was collected or to comply with legal, regulatory or internal policy requirements.</p>
        <br/>
        <h3 className="font-semibold text-title-24 pb-4" >Changes to our privacy and security statement</h3>
        <p className="pb-2">We may amend this Privacy and Security Statement from time to time. We encourage you to revisit this page periodically to read the current version of this Statement in effect. If you have any concerns or questions about this Statement or if you would like to lodge a complaint, please contact office@dilig.net.</p>
        <br/>
        <h3 className="font-semibold text-title-24 pb-4">Children under the age of 15</h3>
        <p className="pb-2">Our websites are not intended for children under 15 years of age. If you become aware that your child has provided us with personal information without your consent, please contact us at office@dilig.net and we will work to delete it. We do not knowingly collect personal information from children under 15.</p>
        <br/>
        <h3 className="font-semibold text-title-24 pb-4">Contact information</h3>
        <p className="pb-2">If you have any questions or comments about this Privacy and Security Statement, please contact us by emailing us at office@dilig.net.</p>
        <p className="pb-2">or by writing to us at:</p>
        <p className="pb-2">Diliget software</p>
        <p className="pb-2">Attention: Legal Department, Privacy Statement Issues</p>
        <p className="pb-2">Zetska 36, Niš 18 000, Serbia</p>
      </Wrapper>
    </div>
    
  );
};

export default PrivacyPolicy;
