import ActionCard from '../components/shared/ActionCard';
import PageLayout from '../layout/PageLayout';
import Wrapper from '../layout/Wrapper';
import net from './../assets/icons/caseStudy/net.svg';
import angular from './../assets/icons/caseStudy/angular.svg';
import java from './../assets/icons/caseStudy/java.svg';
import mssql from './../assets/icons/caseStudy/MSSQL.svg';
import raspberry from './../assets/icons/caseStudy/raspberrypi.svg';
import ionic from './../assets/icons/caseStudy/ionic.svg';
import { useEffect } from 'react';
import { motion } from 'framer-motion';
import TechNuggets from '../components/shared/TechNuggets';
import useAnalytics from '../hooks/useAnalytics';
const numbers = [
  {
    value: 10,
    static: '+',
    title: 'Projects',
  },
  {
    value: 10,
    static: '+',
    title: 'Years Project Duration',
  },
  {
    value: '1,5m',
    static: '+',
    title: 'Transactions per Year',
  },
];

const _data = {
  heading: {
    subheading: 'Case Study',
    heading: 'Ticketing System for passengers',
    imgUrl:
      'https://lh6.googleusercontent.com/iYNVVAYNEmKe9mY9IFK39EUWT2GZLjnZjF0QoghP8HV1_q8arGWEryvCcPhOWRzRTwU=w2400',
    paragraph: 'Ticketing system for passenger transportation company Niš Ekspres',
  },

  about: {
    heading: 'About the Client',
    paragraph:
      'Niš Ekspres is one of the largest passenger transportation companies in Western Balkan. The company was founded in 1951 and has been providing quality service to its customers ever since. The main activity of the company is the transportation of passengers on the city, intercity, and international routes. Niš Ekspres is known for its reliability and safety and has built a strong reputation over the years.',
    country_heading: 'Country',
    country_name: 'Serbia',
    industry_heading: 'Industry',
    industry_name: 'Transportation',
  },

  domain: {
    heading: 'Domain',
    paragraph:
      'Our goal was to prove the current system and quality of their services with more advanced technology. Some of our client’s needs were: the expansion of ticket sales methods, the addition of a monitoring system, an improved reporting system, introduction of specific hardware to improve services.',
  },

  challanges: {
    heading: 'Challanges',
    paragraph:
      'There are many different types of applications based on a platform where they will be executed. There are a lot of specific hardware systems (ex. Cameras, Barcode scanner, etc.) that needed to be connected with some parts of our system. Data between components must be shared in real-time.',
  },

  solution: {
    heading: 'Solution',
    paragraph:
      'We have expanded our knowledge even more with new technologies and embedded devices. Also, we proved to ourselves that we can maintain complex systems on both hardware and software levels.',
    imgUrl:
      'https://lh6.googleusercontent.com/gE3bZuCNhNXTUdFUf16c_gzzMnL8P2tG1koMKHCdJQKfiiKyjXpbv3bfW5pqOUkncAE=w2400',
  },

  results: {
    heading: 'Results',
    list: [
      {
        id: 1,
        text: 'Innovations introduced with the latest hardware in the industry',
      },
      {
        id: 2,
        text: 'Increased number of tickets sold online',
      },
      {
        id: 3,
        text: 'Improved ticket control',
      },
      {
        id: 4,
        text: 'Improved reporting system',
      },
      {
        id: 5,
        text: 'Added new services to customers',
      },
    ],
  },

  technologies: [
    {
      id: 1,
      link: net,
    },
    {
      id: 2,
      link: angular,
    },
    {
      id: 3,
      link: java,
    },
    {
      id: 4,
      link: mssql,
    },
    {
      id: 5,
      link: ionic,
    },
    {
      id: 6,
      link: raspberry,
    },
  ],
};

export default function CaseStudyTicketing() {
  useEffect(() => {
    document.title = 'Case Study: Ticketing System For Passengers';
  }, []);

  useAnalytics('Case Study: Ticketing System For Passengers');

  return (
    <PageLayout>
      <div className="bg-baby-blue dark:bg-dg-primary-1700 w-full pt-20 md:pt-24">
        {/* Heading Section */}
        <section
          id="heading"
          className="flex flex-col items-center justify-center m-auto py-16 md:py-32 bg-[url('https://lh6.googleusercontent.com/iYNVVAYNEmKe9mY9IFK39EUWT2GZLjnZjF0QoghP8HV1_q8arGWEryvCcPhOWRzRTwU=w2400')] bg-cover"
        >
          <div className="my-8 flex flex-col md:flex-row justify-start items-center w-full max-w-custom px-8 xl:px-0">
            <div className="w-full">
              <h6 className="subheading">{_data.heading.subheading}</h6>
              <h1 className="heading text-dg-secondary mt-2">{_data.heading.heading}</h1>
              <p className="paragraph mt-4">{_data.heading.paragraph}</p>
            </div>
          </div>
        </section>

        <Wrapper padding={' py-90p'}>
          <motion.section
            id="status-numbers"
            className="flex flex-col md:flex-row items-center justify-between w-full gap-90p px-90p"
            initial={{ y: 60, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            {numbers.map((item, i) => (
              <div key={i} className="flex flex-col">
                <h2 className="display-number text-center">
                  {item.value}
                  {item.static}
                </h2>
                <h3 className="number-title text-center">{item.title}</h3>
              </div>
            ))}
          </motion.section>
        </Wrapper>

        {/* About the Client Section */}
        <section id="client" className="flex flex-col items-start justify-center">
          <div className="my-8 flex flex-col md:flex-row justify-center items-start w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full md:w-1/2">
              <h3 className="h3-heading text-teal-600">{_data.about.heading}</h3>
              <p className="paragraph mt-4">{_data.about.paragraph}</p>
            </div>
            <div className="w-full md:w-1/2 grid grid-cols-2 md:grid-cols-3 gap-16 mt-8 md:mt-0">
              <div className="hidden md:inline-block"></div>
              <div className="float-left md:float-right text-left md:text-right">
                <h4 className="text-teal-600 font-semibold">
                  {_data.about.country_heading}
                </h4>
                <p className="mt-4">{_data.about.country_name}</p>
              </div>
              <div className="float-left md:float-right text-left md:text-right">
                <h4 className="text-teal-600 font-semibold">
                  {_data.about.industry_heading}
                </h4>
                <p className="mt-4">{_data.about.industry_name}</p>
              </div>
            </div>
          </div>
        </section>

        {/* Domain Section */}
        <section id="domain" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading">{_data.domain.heading}</h3>
              <p className="paragraph mt-4">{_data.domain.paragraph}</p>
            </div>
          </div>
        </section>

        {/* Challanges, Solution Section */}
        <section
          id="challanges_solution"
          className="flex flex-col items-center justify-center mt-16"
        >
          <div className="my-8 flex flex-col md:flex-row justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full md:w-1/2 md:pr-16">
              <div>
                <h3 className="h3-heading">{_data.challanges.heading}</h3>
                <p className="text-sm text-dark-gray dark:text-white mt-4">
                  {_data.challanges.paragraph}
                </p>
              </div>
              <div className="mt-8">
                <h3 className="h3-heading">{_data.solution.heading}</h3>
                <p className="text-sm text-dark-gray dark:text-white mt-4">
                  {_data.solution.paragraph}
                </p>
              </div>
            </div>
            <img
              src={_data.solution.imgUrl}
              alt="Case Study main image"
              className="text-center w-full md:w-1/2"
            />
          </div>
        </section>

        {/* Results Section */}
        <section id="results" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading text-dg-secondary">{_data.results.heading}</h3>
              <ul className="list-disc paragraph mt-2 pl-8">
                {_data.results.list.map(item => (
                  <li key={item.id}>{item.text}</li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        {/* Technologies Section */}
        <section id="technologies" className="flex flex-col mt-16">
          <div className="my-8 flex flex-col w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading">Technologies</h3>
            </div>
            <TechNuggets
              tech={['.Net', 'Angular', 'Java', 'MSSQL', 'Ionic', 'Raspberry PI']}
            />
          </div>
        </section>

        {/* CTA Section */}
        <section id="cta" className="flex flex-col items-center justify-center mt-16">
          <div className="px-8 mt-8 mb-32 w-full max-w-custom">
            <ActionCard
              title="Let's Work Together!"
              text="Grow faster with a dedicated team of .NET & JS experts."
              btn1="More Projects"
              btn2="Contact Us"
              link1={'/portfolio'}
              link2={'/contact'}
            />
          </div>
        </section>
      </div>
    </PageLayout>
  );
}
