import React, { useState } from "react";
import {ReactComponent as WidgetSVG} from '../assets/graphics/HiringWidget.svg'
import './../App.css'


export default function HiringWidget() {
    const [isHover,setHover] = useState(false);

    return (
        <a href="/careers">
            <div className={"size-07 absolute right-[-50px] top-[90px] md:top-0 md:right-0 transition-all px-6 py-4"} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            
            <div className={"h-[182px] w-[174px] relative flex justify-center items-center"}>
            <div className="absolute top-0 left-0 h-full w-full transition-all hover:transition-all">
            <WidgetSVG className={"transition-all" + (isHover ? " scale-125" : "")} />
              </div>
            <h2 className={"uppercase text-sm z-10 mb-8 ml-6 text-white transition-all" + (isHover ? " scale-125" : "")}>Now <br></br> Hiring</h2>
            </div>
            
              
              
            </div>
          </a>
    )
}
