import CaseStudy from './components/CaseStudy';
import About from './pages/About';
import Careers from './pages/Careers';
import CaseStudyBI from './pages/CaseStudyBI';
import CaseStudyCentralized from './pages/CaseStudyCentralized';
import CaseStudyFinantial from './pages/CaseStudyFinantial';
import CaseStudyResource from './pages/CaseStudyResource';
import CaseStudyStrata from './pages/CaseStudyStrata';
import CaseStudyTicketing from './pages/CaseStudyTicketing';
import ContactPage from './pages/ContactPage';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProcessPage from './pages/ProcessPage';
import WorkWithUs from './pages/WorkWithUs';
import EventsTimeline from './pages/EventsTimeline';
import DiligentLife from './pages/Culture';
import DiligentMinds from './pages/DiligentMinds';
import Blog from './pages/Blog';
import Culture from './pages/Culture';
const routes = [
  {
    path: '/',
    component: <Home />,
    title: 'Diligent Software',
    exact: true,
  },
  {
    path: '/workwithus',
    component: <WorkWithUs />,
    title: 'Work with Us',
    exact: true,
    nav: true,
  },
  {
    path: '/portfolio',
    component: <Portfolio />,
    title: 'Case Studies',
    exact: true,
    nav: true,
  },
  {
    path: '/process',
    component: <ProcessPage />,
    title: 'Process',
    exact: true,
    nav: true,
  },
  {
    path: '/casestudybi',
    component: <CaseStudyBI />,
    title: 'BI Healthcare System',
    exact: true,
  },
  {
    path: '/casestudystrata',
    component: <CaseStudyStrata />,
    title: 'Health Tracking Software',
    exact: true,
  },
  {
    path: '/casestudyfinancial',
    component: <CaseStudyFinantial />,
    title: 'Financial Engine',
    exact: true,
  },
  {
    path: '/casestudyticketing',
    component: <CaseStudyTicketing />,
    title: 'Ticketing System for Passengers',
    exact: true,
  },
  {
    path: '/casestudycentralized',
    component: <CaseStudyCentralized />,
    title: 'Centralized Monitoring System',
    exact: true,
  },
  {
    path: '/casestudyresource',
    component: <CaseStudyResource />,
    title: 'Resource Planning System',
    exact: true,
  },
  {
    path: '/careers',
    component: <Careers />,
    title: 'Careers',
    exact: true,
    nav: true,
    drop: [
      {
        path: '/culture',
        component: <Culture />,
        title: 'Culture',
        exact: true,
        nav: true,
      },
      //enable after we add content
      // {
      //   path:"/diligentminds",
      //   component: <DiligentMinds />,
      //   title: 'Diligent Minds',
      //   exact: true,
      //   nav:true,
      // },
      // {
      //   path:"/eventstimeline",
      //   component: <EventsTimeline />,
      //   title: 'Events Timeline',
      //   exact: true,
      //   nav:true,
      // },
    ],
  },
  {
    path: '/blog',
    component: <Blog />,
    title: 'Blog',
    exact: true,
    nav: true,
  },
  {
    path: '/about',
    component: <About />,
    title: 'About Us',
    exact: true,
    nav: true,
  },
  {
    path: '/contact',
    component: <ContactPage />,
    title: 'Contact Us',
    exact: true,
  },
  {
    path: '/privacypolicy',
    component: <PrivacyPolicy />,
    title: 'Privacy Policy',
    exact: true,
  },
];

export default routes;
