import React from 'react'
import PropTypes from 'prop-types'
import FacelessSlider from './shared/FacelessSlider/FacelessSlider'
import ProcessSliderWrapper from './shared/ProcessSliderWrapper'
import ProcessFacelessSlider from './shared/ProcessFacelessSlider'

const ProcessSlider = ({images}) => {
  return (
    <>  
        <ProcessSliderWrapper>
            <ProcessFacelessSlider images={images}/>
        </ProcessSliderWrapper>
        
    </>
  )
}

ProcessSlider.propTypes = {}

export default ProcessSlider