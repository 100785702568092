import { useState, useEffect } from 'react';
import axios from 'axios';

import ImgStep1 from '../assets/images/CaseStudy/CentralizedSmall.png';

import Care from './../assets/icons/values/care.svg';
import Doing from './../assets/icons/values/doing.svg';
import Culture from './../assets/icons/values/culture.svg';
import Ideas from './../assets/icons/values/ideas.svg';

import CardsGrid from '../components/CardsGrid';

import Animation_Diligent from '../assets/animation_diligent.webm';
import Wrapper from '../layout/Wrapper';
import PageTitle from '../components/shared/PageTitle';
import HighlighedText from '../components/shared/HighlighedText';
import CardValues from '../components/shared/CardValues';
import CardLife from '../components/shared/CardLife';
import ActionCard from '../components/shared/ActionCard';
import AboutUsSlider from '../components/shared/AboutUsSlider';
import CardCareers2 from '../components/CareerCardsTemplates/CardCareers2';
import OrbitOnScroll from '../components/shared/graphics/OrbitOnScroll';
import PageLayout from '../layout/PageLayout';
import TimelineCard from '../components/TimelineCard';
//import useAnalytics from '../hooks/useAnalytics';

// eslint-disable-next-line no-underscore-dangle
const _data = {
  heading: {
    heading: 'Join Our Team of Diligent Minds',
    subheading: 'Careers',
  },
  job: {
    NetDev: {
      id: 1,
      templateFlag: 1,
      role: '.Net Developer',
      nugget: '.Net',
      shortDetails:
        'An idea solves a problem. We help you to create that idea, build a product and scale it to be successful in your business.',
      extended: {
        paragraph:
          'Team Diligent is constantly growing! We are looking for a team player that will work with experienced engineers. If technology is your passion and you are ready to move the boundaries of your knowledge every day, then, Diligent is the right place for you. If you are not from Niš, we are offering a full remote position.',
        requirements: [
          'Good software development fundamentals and knowledge of .NET architecture concepts & patterns',
          'Good knowledge of software design patterns',
          'Good knowledge of databases and database design',
          'Experience in working with microservices is a big plus',
          'The ability to work in a big team but also to work independently',
          'Excellent communication skills',
        ],
        key: [
          'Working as a full-stack developer on various project and products',
          'Working with 3rd-party APIs',
          'Working on different integration scenarios',
          'Setting up project structure and architecture',
          'Being involved in full project development, from writing a specification to deploying a finished product',
        ],
        offer: [
          'Full Remote position',
          'A fast-growth company with stable projects and strong international clients',
          'Opportunity to work in teams with experienced engineers',
          'Competitive employment conditions',
          'An environment that will make you feel good about your job',
          'Challenging and diverse projects',
          'Support in your personal and professional growth',
          'Flexible working hours',
          'Private health insurance',
        ],
      },
    },
    NetDevIntern: {
      id: 2,
      templateFlag: 2,
      role: '.Net Developer Internship',
      nugget: '.NetInernship',
      shortDetails:
        'An idea solves a problem. We help you to create that idea, build a product and scale it to be successful in your business.',
      extended: {
        paragraph:
          'Team Diligent is constantly growing! We are looking for a team player that will work with experienced engineers. If technology is your passion and you are ready to move the boundaries of your knowledge every day, then, Diligent is the right place for you. If you are not from Niš, we are offering a full remote position.',
        offer: [
          'Paid internship',
          'Full Remote position',
          'A fast-growth company with stable projects and strong international clients',
          'Experience of working in a team',
          'Work on real project with real clients',
          'Mentorship from industry leaders',
          'Support in your personal and professional growth',
          'Lots of team activities and perks',
          'Modern working place with a positive and fun atmosphere',
        ],
        requirements: [
          'Fundamentals of data structures ',
          'Fundamentals and knowledge of .NET architecture concepts & patterns',
          'Fundamentals of databases and database design',
          'Team player and fast learner',
          'Analytical and problem-solving skills',
        ],
        selectionProcess: [
          'Application',
          'CV Screening',
          'HR Interview',
          'Technical Interview',
          'Internship - offer & contract',
        ],
        conslusionParagraph:
          'At the end of the selection process, we will select interns able to join our team for a 3 months internship and arrange the beginning of the practice personally with you.',
      },
    },
  },
  life: {
    heading: 'A Culture That’s Serious About Work and Fun',
    subheading: 'Diligent life',
    italic: {
      heading: 'Life At diligent',
      paragraph:
        'From the start, you can expect to be challenged and supported. We provide a encouraged atmosphere with knowledgeable mentors to help you advance in your career.  To create an inspiring work life, we collaborate as a team both inside and outside of the office.',
      heading2: 'What It Means to Work With Us',
    },
    cards: [
      {
        id: 1,
        title: 'Shape the Future',
        paragraph:
          'We collaborate with business leaders and entrepreneurs to disrupt and push their industries forward. From startup ideas to enterprise-level product & software development, we work together as a team to transform our clients’ ideas into reality.',
      },
      {
        id: 2,
        title: 'Life-Long Learning',
        paragraph:
          'We believe that learning is a journey that never ends. With us, you will have the opportunity to continuously learn in an environment surrounded by other highly skilled professionals with decades of experience.  Also, there are several chances for you to develop through the use of various technologies, involvement in the product definition process, conference attendance, and more.',
      },
      {
        id: 3,
        title: 'A Unique Culture',
        paragraph:
          'Everyone talks about a work-life balance, we do it - for two reasons. Firstly, we believe in an environment of happy people. Secondly, even if you’re highly productive, the only way to maintain productivity long-term is by taking time for the things that make you happy.',
      },
      {
        id: 4,
        title: 'Make the Impossible Better',
        paragraph:
          "If you have a mindset that is continuously focused on pushing through and beyond your boundaries, conquering a whole new challenge every day in an atmosphere where you constantly learn and improve, this is the place for you. We'd love to learn more about you.",
      },
    ],
  },
  values: {
    heading: 'Values',
    cards: [
      {
        id: 0,
        iconUrl: Care,
        title: 'Care',
        paragraph:
          'We believe that by working together and being kind to one another, we can make a difference. We care about work colleagues, ourselves, partnerships, but also the planet. We constantly strive to be helpful, kind, and inclusive in everything we do and looking for ways to be more sustainable. ',
      },
      {
        id: 1,
        iconUrl: Culture,
        title: 'Culture',
        paragraph:
          'Our people love what they do. We provide a fun and supportive environment that empowers our staff to grow, learn, and thrive. We are consistent and transparent in our actions and committed to our clients and colleagues. We believe that together we can achieve more.',
      },
      {
        id: 2,
        iconUrl: Doing,
        title: 'Learn by Doing',
        paragraph:
          'Our legacy is our impact on the people around us. By being kind and helping others, we can make a positive difference and leave a lasting impression. We grow as individuals, as well as we grow as a team.',
      },
      {
        id: 3,
        iconUrl: Ideas,
        title: 'Ideas Over Hierarchy',
        paragraph:
          'We believe that the best ideas can come from anywhere, both inside and outside our company. Our job is to seek out those ideas, shape and improve them through candid debate, and take them from concept to action.',
      },
    ],
  },
  cards: [
    {
      id: 0,
      title: 'BI Healthcare Solution System',
      imgUrl:
        'https://lh6.googleusercontent.com/D7N87i3udAln4YBp5SbaSI-9r2pVnnT5K2VT6p0G3dQanVgTMC2tdgz71PWOYco-7yQ=w2400',
      alt: 'BI Healthcare Solution System',
      link: '/casestudybi',
    },
    {
      id: 1,
      title: 'Resource Planning System',
      imgUrl:
        'https://lh5.googleusercontent.com/HLOh5coHfcEgDuftj1pOA9f1865xiIom5vyxTWNMKqMiivxL8Lg4c9ACzbfYYUdeuqQ=w2400',
      alt: 'Resource Planning System',
      link: '/casestudyresource',
    },
    {
      id: 2,
      title: 'Ticketing System for Passengers',
      imgUrl:
        'https://lh5.googleusercontent.com/f_G0H0C_qLHhsU8PBj6uTkNigzKiXzd24B_pgJ6UqVmBKlU2Lyxv2r5lf6uvY9d_0PY=w2400',
      alt: 'Ticketing System for Passengers',
      link: '/casestudyticketing',
    },
  ],
  connect: {
    heading: 'Connect and Grow With Us',
    paragraph:
      'At Diligent, we are passionately proud of our culture and feel that everyone, including those who are not yet on the team, should have the opportunity to experience it. Because of this, we participate in Open Doors. Our coworkers are actively involved in the local tech communities. We take satisfaction in giving back as well, assisting college students or seasoned professionals who want to start a career in IT.',
    subtitle: 'Check out how we have fun together!',
    sliderImages: [],
  },
  ActionCard: {
    heading: 'Step up Your Career!',
    paragraph:
      'We are continuously on the lookout for talented people to grow our business.',
    primaryBtn: 'Apply',
    secondaryBtn: 'About Us',
  },
};

export default function Careers({ forwardedRef }) {
  const [clickedPosition, setClickedPosition] = useState('');
  const [cntCareers, setCntCareers] = useState('');
  const [isLoaded, setIsLoaded] = useState('');

  const api_url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    document.title = 'Careers';
  }, []);

  //useAnalytics();

  useEffect(async () => {
    var vid = document.getElementById('animation');
    vid.playbackRate = 2;
    axios
      .get(
        `${api_url}/api/careerspage?populate[0]=heading&populate[1]=info&populate[2]=job.icon`,
      )
      .then(res => {
        //console.log(res.data.data.attributes);
        setCntCareers(res.data.data.attributes);
        setIsLoaded(true);
      })
      .catch(err => {
        console.log(err);
        setIsLoaded(false);
      });
  }, []);

  if (!isLoaded) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  }

  return (
    <PageLayout>
      <div className="bg-white dark:bg-dg-primary-1700 w-full pt-90p overflow-hidden">
        {/* Diligent Minds */}
        <Wrapper padding={' py-90p'}>
          <PageTitle
            left
            heading="Personal Development & Success Stories"
            subheading="Diligent Minds"
          />
          <Wrapper padding={' py-72p'}>
            <CardsGrid data={_data.cards} />
          </Wrapper>
          <div className="flex flex-col md:flex-row items-center justify-center gap-8">
            <p className="paragraph w-full md:w-1/2 lg:w-3/4">
              If you have a mindset that is continuously focused on pushing through and
              beyond your boundaries, conquering a whole new challenge every day in an
              atmosphere where you constantly learn and improve, this is the place for
              you. We'd love to learn more about you.
            </p>
            <button className="btn-secondary">
              Read More
            </button>
          </div>
        </Wrapper>

        {/* Action Card */}
        <Wrapper>
          <div className="my-90p">
            <ActionCard
              title={_data.ActionCard.heading}
              text={_data.ActionCard.paragraph}
              btn1={_data.ActionCard.primaryBtn}
              btn2={_data.ActionCard.secondaryBtn}
              link1={'/contact'}
              link2={'/about'}
            />
          </div>
        </Wrapper>
      </div>
    </PageLayout>
  );
}
