import ActionCard from '../components/shared/ActionCard';
import PageLayout from '../layout/PageLayout';

import net from './../assets/icons/caseStudy/net.svg';
import angular from './../assets/icons/caseStudy/angular.svg';
import mssql from './../assets/icons/caseStudy/MSSQL.svg';
import { useEffect } from 'react';
import { motion } from 'framer-motion';
import Wrapper from '../layout/Wrapper';
import TechNuggets from '../components/shared/TechNuggets';
import useAnalytics from '../hooks/useAnalytics';
const numbers = [
  {
    value: 5,
    static: '+',
    title: 'Team Members',
  },
  {
    value: 3,
    static: '+',
    title: 'Years Project Duration',
  },
  {
    value: '1,6m',
    static: '+',
    title: 'Transactions per Year',
  },
];

const _data = {
  heading: {
    subheading: 'Case Study',
    heading: 'Financial Engine',
    imgUrl:
      'https://lh3.googleusercontent.com/pCtBoMQ4ea-MJV9TJzWucWkMOo0_0fetGCXblvffooAn9bG_ARbWNScRBBO16dNNrnk=w2400',
    paragraph: 'Implementing a system for financial system management',
  },

  about: {
    heading: 'About the Client',
    paragraph:
      'The client that deals with precious metals trading, storing, and investment had us expand the existing IT system with a sub-system that monitors and analyze the financial perspective of the business.',
    country_heading: 'Country',
    country_name: 'USA',
    industry_heading: 'Industry',
    industry_name: 'Fintech',
  },

  domain: {
    heading: 'Domain',
    paragraph:
      'Software that enterprise businesses use should answer clients’ business needs, help improve their financial management, and capture and represent all financial activity easily and understandably. This software can help businesses keep track of their income and expenses, understand their financial activity, and make better financial decisions.',
  },

  challanges: {
    heading: 'Challanges',
    paragraph:
      'Integrating our software into such a large IT system was a challenge because every integration with different parts of their IT system was the story for itself. Deadlines were tight because the client needed this functionality as soon as possible to improve their daily financial management. However, we were able to overcome these challenges and successfully integrate our software into their system.',
  },

  solution: {
    heading: 'Solution',
    paragraph:
      'We secured our position as the main software development partner which also led to future projects and contracts with the client. We used the project to further expand our knowledge about systems for financial management.',
    imgUrl:
      'https://lh3.googleusercontent.com/8E5PkTqBrT4anQvo9Z--Tl7O0U8YnfY21UzxBIoqvmJLZTvOZKSTZrzPpzTr-M0BoBw=w2400',
  },

  results: {
    heading: 'Results',
    list: [
      {
        id: 0,
        text: 'Identification and capturing all of the money flow inside their business',
      },
      {
        id: 1,
        text: 'Notifying 3rd party systems',
      },
      {
        id: 2,
        text: 'Reporting stakeholders',
      },
      {
        id: 3,
        text: 'Tracking activities and balances',
      },
      {
        id: 4,
        text: 'Suggesting money movement',
      },
    ],
  },

  technologies: [
    {
      id: 1,
      link: net,
    },
    {
      id: 2,
      link: angular,
    },
    {
      id: 3,
      link: mssql,
    },
  ],
};

export default function CaseStudyFinantial() {
  useEffect(() => {
    document.title = 'Case Study: Financial Engine';
  }, []);

  useAnalytics('Case Study: Financial Engine');

  return (
    <PageLayout>
      <div className="bg-baby-blue dark:bg-dg-primary-1700 w-full pt-20 md:pt-24">
        {/* Heading Section */}
        <section
          id="heading"
          className="flex flex-col items-center justify-center m-auto py-16 md:py-32 bg-[url('https://lh3.googleusercontent.com/pCtBoMQ4ea-MJV9TJzWucWkMOo0_0fetGCXblvffooAn9bG_ARbWNScRBBO16dNNrnk=w2400')] bg-cover"
        >
          <div className="my-8 flex flex-col md:flex-row justify-start items-center w-full max-w-custom px-8 xl:px-0">
            <div className="w-full">
              <h6 className="subheading">{_data.heading.subheading}</h6>
              <h1 className="heading text-dg-secondary mt-2">{_data.heading.heading}</h1>
              <p className="paragraph mt-4">{_data.heading.paragraph}</p>
            </div>
          </div>
        </section>

        <Wrapper padding={' py-90p'}>
          <motion.section
            id="status-numbers"
            className="flex flex-col md:flex-row items-start justify-between w-full gap-90p px-90p"
            initial={{ y: 60, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            {numbers.map((item, i) => (
              <div key={i} className="flex flex-col">
                <h2 className="display-number text-center">
                  {item.value}
                  {item.static}
                </h2>
                <h3 className="number-title text-center">{item.title}</h3>
              </div>
            ))}
          </motion.section>
        </Wrapper>

        {/* About the Client Section */}
        <section id="client" className="flex flex-col items-center justify-center">
          <div className="my-8 flex flex-col md:flex-row justify-center items-start w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full md:w-1/2">
              <h3 className="h3-heading text-teal-600">{_data.about.heading}</h3>
              <p className="paragraph mt-4">{_data.about.paragraph}</p>
            </div>
            <div className="w-full md:w-1/2 grid grid-cols-2 md:grid-cols-3 gap-16 mt-8 md:mt-0">
              <div className="hidden md:inline-block"></div>
              <div className="float-left md:float-right text-left md:text-right">
                <h4 className="text-teal-600 font-semibold">
                  {_data.about.country_heading}
                </h4>
                <p className="mt-4">{_data.about.country_name}</p>
              </div>
              <div className="float-left md:float-right text-left md:text-right">
                <h4 className="text-teal-600 font-semibold">
                  {_data.about.industry_heading}
                </h4>
                <p className="mt-4">{_data.about.industry_name}</p>
              </div>
            </div>
          </div>
        </section>

        {/* Domain Section */}
        <section id="domain" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading">{_data.domain.heading}</h3>
              <p className="paragraph mt-4">{_data.domain.paragraph}</p>
            </div>
          </div>
        </section>

        {/* Challanges, Solution Section */}
        <section
          id="challanges_solution"
          className="flex flex-col items-center justify-center mt-16"
        >
          <div className="my-8 flex flex-col md:flex-row justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full md:w-1/2 md:pr-16">
              <div>
                <h3 className="h3-heading">{_data.challanges.heading}</h3>
                <p className="text-sm text-dark-gray dark:text-white mt-4">
                  {_data.challanges.paragraph}
                </p>
              </div>
              <div className="mt-8">
                <h3 className="h3-heading">{_data.solution.heading}</h3>
                <p className="text-sm text-dark-gray dark:text-white mt-4">
                  {_data.solution.paragraph}
                </p>
              </div>
            </div>
            <img
              src={_data.solution.imgUrl}
              alt="Case Study main image"
              className="text-center w-full md:w-1/2"
            />
          </div>
        </section>

        {/* Results Section */}
        <section id="results" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading text-dg-secondary">{_data.results.heading}</h3>
              <ul className="list-disc paragraph mt-2 pl-8">
                {_data.results.list.map(item => (
                  <li key={item.id}>{item.text}</li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        {/* Technologies Section */}
        <section id="technologies" className="flex flex-col mt-16">
          <div className="my-8 flex flex-col w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading">Technologies</h3>
            </div>
            <TechNuggets
              tech={[
                '.Net',
                'Service-Oriented Architecture (SOA)',
                'MS SQL Server',
                'MS MVC',
                'Event-Driven Architecture (EDA)',
              ]}
            />
          </div>
        </section>

        {/* CTA Section */}
        <section id="cta" className="flex flex-col items-center justify-center mt-16">
          <div className="px-8 mt-8 mb-32 w-full max-w-custom">
            <ActionCard
              title="Let's Work Together!"
              text="Grow faster with a dedicated team of .NET & JS experts."
              btn1="More Projects"
              btn2="Contact Us"
              link1={'/portfolio'}
              link2={'/contact'}
            />
          </div>
        </section>
      </div>
    </PageLayout>
  );
}
