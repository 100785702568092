import React, { useRef, useEffect } from 'react';
import { useScroll } from 'framer-motion';

const api_url = process.env.REACT_APP_API_URL;

const HRProcess = ({ data }) => {

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ container: ref });


  useEffect(() => {
    const imageArray = document.querySelectorAll('.image-switch');
    const switchImage = scrollYProgress.onChange(value => {
      if (value <= 0.1) {
        imageArray.forEach(el => el.classList.remove('active-image'));
        imageArray[0].classList.add('active-image');
      }
      if (value > 0.1) {
        imageArray.forEach(el => el.classList.remove('active-image'));
        imageArray[1].classList.add('active-image');
      }
      if (value > 0.4) {
          imageArray.forEach(el => el.classList.remove('active-image'));
          imageArray[2].classList.add('active-image');
      }
      if (value > 0.6) {
        imageArray.forEach(el => el.classList.remove('active-image'));
          imageArray[3].classList.add('active-image');
      }
      if (value > 0.8) {
        imageArray.forEach(el => el.classList.remove('active-image'));
          imageArray[4].classList.add('active-image');
      }
    });

    return () => {
      switchImage();
    };
  });

  return (
    <div
      ref={ref}
      id="steps-container"
      className="steps-container no-scrollbar flex flex-col items-center justify-start overflow-auto h-[75vh] pt-72p pl-3"
    >
      {/* Dynamic Image */}
      {data.selection_process.steps.map((item, index) => 
        (item.media.data && 
          <img
          key={index}
          src={api_url + item.media.data.attributes.url}
          alt={item.media.data.attributes.alternativeText}
          className={
            'image-switch bg-fixed hidden sm:block opacity-0 absolute top-1/4 right-10 w-2/5  transition-all' +
            (index == 0 ? ' active-image' : '')
          }
        /> )
        
      )}

      <div className="local">
        {data.selection_process.steps.map((item, index) => (
          <>
            {/* Section */}
            <div
              id={item.id}
              className="relative flex flex-col sm:flex-row items-start justify-center sm:gap-16 min-h-fit max-h[100vh] mb-28 md:mb-56"
            >
              {/* Line */}
              <hr className="bg-gray-400 w-full absolute -left-1/2 rotate-90" />

              {/* Dot */}
              <div className="z-10 p-[0.36rem] rounded-full bg-white border-solid border border-dg-primary-900 absolute -left-[0.63%] top-[1%]"></div>

              <div className="flex flex-col sm:w-1/2 ml-8">
                <h3 className="h3-heading">{item.heading}</h3>
                <p className="text-sm text-dark-gray dark:text-white mt-4">
                  {item.paragraph}
                </p>
                {item.cto != null && (
                  <a href='#jobs' className="btn-secondary mt-6 min-w-fit">{item.cto}</a>
                )}
              </div>
              <div className="w-1/2"></div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default HRProcess;
