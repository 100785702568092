import React, { Fragment, useEffect, useState } from 'react';

const api_url = process.env.REACT_APP_API_URL;

const PortfolioCardNew = ({ data }) => {

  console.log(data);

  return (
    <Fragment>
      {data && (
        <a className={'card box my-2 flex- flex-col items-center'} href={`/case-studies/${data.Slug}`}>
          {data.BackgroundImage && (
            <img
              src={api_url + data.BackgroundImage.data.attributes?.url}
              alt={data.BackgroundImage.data.attributes?.alternativeText}
              className={'mb-12 mx-auto self-center'}
            ></img>
          )}

          <h3 className="h3-heading">{data.Heading.title}</h3>
          <button className="btn text-dg-secondary mt-4">Read More</button>
        </a>
      )}
    </Fragment>
  );
};

export default PortfolioCardNew;
