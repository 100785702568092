import bg_socials from '../../assets/logos/socials_bg.png';
import social_1 from '../../assets/logos/social_1.jpg';
import social_2 from '../../assets/logos/social_2.jpg';
import social_3 from '../../assets/logos/social_3.jpg';

import SocialMediaLinks from './SocialMediaLinks';
import { motion } from 'framer-motion';



export default function SocialNetworks() {
  return (
    <div className="my-16 flex justify-center items-center">
      <div className="flex flex-col justify-center items-center text-center max-w-custom">
        <h6 className="subheading">
          Social Medias
        </h6>
        <h1 className="heading mt-2">Follow Us</h1>
        <p className="paragraph mt-4">
          We provide value content to developers, creatives and companies alike with our
          social media presence.
        </p>
        {/* Social Media Links */}
        <SocialMediaLinks divWidth="4/5" iconSize="100" iconSizeHover="110" />
        <motion.div
          initial={{ opacity: 0, scale: 1.1 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0, default: { duration: 0.63 } }}
        >
          <div className="flex flex-row items-center justify-center mt-36 mb-24">
            <img
              src={bg_socials}
              alt="Social Medias Background"
              className="w-4/5 md:w-3/5  absolute opacity-80"
            />
            <img
              src={social_1}
              alt="Social Media Post 1"
              className="w-1/3 md:w-1/5 shadow-xl rounded-lg -mr-12 -rotate-12 z-10 transition-all hover:transition-all"
            />
            <img
              src={social_2}
              alt="Social Media Post 2"
              className="w-1/3 md:w-1/5 shadow-xl rounded-lg z-20 transition-all hover:transition-all"
            />
            <img
              src={social_3}
              alt="Social Media Post 3"
              className="w-1/3 md:w-1/5 shadow-xl rounded-lg -ml-12 rotate-12 z-10 transition-all hover:transition-all"
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
}
