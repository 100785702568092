import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const api_url = process.env.REACT_APP_API_URL;

const PortfolioCard = ({ title, isLarge, link, img, alt }) => {
  const linkTo = useNavigate();

  return (
    // <div className={"card box" + (isLarge ? ' col-span-2' : '')}>
    <a className={'card box my-2 flex- flex-col items-center'} href={link}>
      <img src={img} alt={alt} className={'mb-12 mx-auto self-center'}></img>
      <h3 className="h3-heading">{title}</h3>
      <button className="btn text-dg-secondary mt-4">Read More</button>
    </a>
  );
};

export default PortfolioCard;
