import PropTypes from 'prop-types';
import logo from '../../assets/logos/LogoColumn.svg';
import bg_socials from '../../assets/logos/socials_bg.png';
import SocialMediaLinks from './SocialMediaLinks';
import { Popover } from '@headlessui/react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

const footerText =
  'Custom software solutions for variety of industries with the emphasis on reliability, innovation and top quality.';

const copyrightText = 'Copyright © 2022. Diligent Software. All rights reserved.';

export default function Footer({ links, scrollToView, activeLinks }) {
  const home = useNavigate();
  const location = useLocation();

  function handleLogo() {
    home('/');
  }

  function checkUrl(event) {

    if (location.pathname === '/') scrollToView(event);
    else home('/contact');
  }

  return (
    <div className="bg-white dark:bg-dg-primary-1600 py-4">
      <div className="flex flex-col justify-center items-center py-4">
        <img
          src={bg_socials}
          alt="Social Medias Background"
          className="w-2/5 absolute opacity-50"
        />
        <img
          src={logo}
          alt="Diligent logo"
          onClick={handleLogo}
          className=" w-16 pb-8 cursor-pointer"
        />
        <p className="text-sm text-gray-400 w-4/5 md:w-1/2 lg:w-1/3 text-center pb-8">
          {footerText}
        </p>
        <Popover.Group as="nav" className="hidden md:flex space-x-10 z-10">
          {links.map((item,index) => (
            <NavLink
              key={index}
              to={item.path}
              className={({ isActive }) => {
                return `text-base font-medium hover:text-dg-primary-900 focus:text-dg-primary-900 active:text-dg-primary-900 capitalize ${activeLinks(
                  isActive,
                  false,
                )}`;
              }}
            >
              {item.title}
            </NavLink>
          ))}
          {/* React Router Link does not support Anchor tags */}
          <div
            onClick={event => checkUrl(event)}
            className="text-base font-medium text-gray-500 hover:text-gray-900 capitalize cursor-pointer"
          >
            Contact
          </div>
        </Popover.Group>
        <SocialMediaLinks divWidth="2/5" iconSize="75" iconSizeHover="95" />
        <a
          href="/privacypolicy"
          className="underline-offset-1 text-xs text-dg-primary-600 text-center cursor-pointer pt-8 z-20"
        >
          Privacy Policy
        </a>
        <p className="text-xs text-gray-400 text-center pt-8">{copyrightText}</p>
      </div>
    </div>
  );
}

Footer.propTypes = {
  forwardedRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  links: PropTypes.arrayOf(PropTypes.object),
  scrollToView: PropTypes.func,
  activeLinks: PropTypes.func,
};
