import React from 'react';
import { motion } from 'framer-motion';
import LandingSVGv2 from './shared/graphics/LandingSVG-v2';
import HiringWidget from './HiringWidget';
import Wrapper from '../layout/Wrapper';


const Landing = ({ heading,numbers,button,paragraph }) => {

  
  return (
    <Wrapper>
      <div className="flex flex-col gap-90p pb-164p">
        <motion.section
          id="landing"
          className="flex flex-col items-center justify-center"
          initial={{ y: 60, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          {/* <img src={bg_home} alt="Diligent Software's Animation" width="700" /> */}

          {/* <LandingSVG /> */}
          <LandingSVGv2 />
          <HiringWidget />
          <div className="mb-16 -mt-12 md:mt-5 flex flex-col lg:flex-row justify-between items-center gap-90p">
            <div className="flex flex-col gap-8p">
              <h6 className="subheading">
                {heading.subtitle}
              </h6>
              
              <h1 className="heading">
                 {heading.title} 
              </h1>
              <p className="paragraph">
                {paragraph}

              </p>
            </div>
            <a
              href="#contact"
              className="btn-primary px-64p py-20p w-full lg:w-max whitespace-nowrap"
            >
              {button}
            </a>
          </div>
        </motion.section>
        <motion.section
          id="status-numbers"
          className="flex flex-col md:flex-row items-center justify-between w-full gap-90p px-90p"
          initial={{ y: 60, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          {numbers.map((item, i) => (
            <div key={i} className="flex flex-col">
              <p className="display-number text-center">
                {item.value}
              </p>
              <h3 className="number-title text-center">{item.title}</h3>
            </div>
          ))}
        </motion.section>
      </div>
    </Wrapper>
  );
};

export default Landing;
