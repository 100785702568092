import React from 'react'
import PropTypes from 'prop-types'

const ArticlesGrid = ({children}) => {
  return (
    <div className='flex flex-col md:mx-32'>
        {children}
    </div>
  )
}

ArticlesGrid.propTypes = {}

export default ArticlesGrid