import React, { useRef } from 'react';

import { useState } from 'react';
import UseAnimations from 'react-useanimations';
import volume from 'react-useanimations/lib/volume';

const api_url = process.env.REACT_APP_API_URL;

const VideoComponent = ({ source, alt }) => {
  const [mute, setMute] = useState(true);

  const vidRef = useRef();
  const muteRef = useRef();

  function toggleMute() {
    var video = vidRef.current;
    video.muted = !video.muted;
    setMute(!mute);
  }

  return (
    <div className="w-full py-48p lg:py-72p relative">
      <video autoPlay loop ref={vidRef} muted className="rounded-[32px]">
        <source type="video/mp4" src={api_url + source} alt={api_url + alt} />
      </video>
      <div className="absolute bottom-16 right-4 md:bottom-24 md:right-8 rounded-full overflow-clip">
        <UseAnimations
          reverse={mute}
          onClick={() => {
            toggleMute();
          }}
          size={50}
          wrapperStyle={{ background: 'white', padding: '5px' }}
          animation={volume}
          speed={0.7}
        />
      </div>

      {/* <div className="">
        <button
          className={'unmuted toggle-sound' + (mute ? ' sound-mute' : null)}
          onClick={() => toggleMute()}
        >
          <div className="sound">
            <div className="sound--icon">
              <Speaker />
            </div>
            <div className="sound--wave sound--wave_one"></div>
            <div className="sound--wave sound--wave_two"></div>
            <div className="sound--wave sound--wave_three"></div>
          </div>
        </button>
      </div> */}
    </div>
  );
};

export default VideoComponent;
