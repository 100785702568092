import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import '../../styles/cards.css';
import { Fragment, useEffect } from 'react';

const api_url = process.env.REACT_APP_API_URL;

const ReactHelmet = ({ seo }) => {
  return (
    <Fragment>
      {seo && (
        <Helmet>
          <title>{seo.metaTitle}</title>
          <meta name="description" content={seo.metaDescription} />
          <link rel="canonical" href={seo.canonicalURL} />
          <meta name="keywords" content={seo.keywords} />
          <meta name="viewport" content={seo.metaViewport} />
          <meta name="robots" content={seo.metaRobots} />
          {seo.metaSocial && seo.metaSocial.lenght > 0 && (
            <Fragment>
              <meta property="og:title" content={seo.metaSocial[0]?.title} />
              <meta
                property="og:image"
                content={`${api_url}${seo.metaSocial[0]?.image?.data?.attributes?.url}`}
              />
              <meta property="og:description" content={seo.metaSocial[0]?.description} />
              <meta property="twitter:title" content={seo.metaSocial[1]?.title} />
              <meta
                property="twitter:image"
                content={`${api_url}${seo.metaSocial[1]?.image?.data?.attributes?.url}`}
              />
              <meta
                property="twitter:description"
                content={seo.metaSocial[1]?.description}
              />
            </Fragment>
          )}
        </Helmet>
      )}
    </Fragment>
  );
};

export default ReactHelmet;
