import React, { useEffect, useState } from 'react';
import Grid from '../layout/Grid';
import PortfolioCard from './PortfolioCard';

const api_url = process.env.REACT_APP_API_URL;

const CardsGrid = ({ data }) => {
  const [state, setState] = useState(data);

  useEffect(() => {
    setState(data);
  }, [data]);

  return (
    <div className="grid overflow-visible grid-cols-1 md:grid-cols-2 lg:grid-cols-3 auto-rows-auto gap-8">
      {state &&
        state.length > 0 &&
        state.map((item, index) => (
          // item.attributes.img.data != null &&
          // <PortfolioCard key={index} title={item.attributes.heading} img={api_url + item.attributes.img.data.attributes.formats.small.url} isLarge={item.attributes.IsLarge} />
          <PortfolioCard
            key={index}
            title={item.title}
            alt={item.alt}
            isLarge={false}
            link={item.link}
            img={item.imgUrl}
          />
        ))}
    </div>
  );
};
export default CardsGrid;
