import React, {useEffect} from "react";

const PageLayout = ({children}) => {

    // useEffect(() => {
    //     window.scrollTo(0, 0)
    //   }, [])
    
    return (
        <div className="">{children}</div>
    );
}

export default PageLayout;