import ActionCard from '../components/shared/ActionCard';
import PageLayout from '../layout/PageLayout';

import net from './../assets/icons/caseStudy/net.svg';
import powerBi from './../assets/icons/caseStudy/power-bi.svg';
import powerAutomate from './../assets/icons/caseStudy/power-automate.svg';
import mssql from './../assets/icons/caseStudy/MSSQL.svg';
import dataFactory from './../assets/icons/caseStudy/data-factory.svg';
import { useEffect } from 'react';
import Wrapper from '../layout/Wrapper';
import { motion } from 'framer-motion';
import TechNuggets from '../components/shared/TechNuggets';
import useAnalytics from '../hooks/useAnalytics';

const numbers = [
  {
    value: 6,
    static: '+',
    title: 'Team Members',
  },
  {
    value: 18,
    static: '+',
    title: 'Months Project Duration',
  },
  {
    value: '9m',
    static: '+',
    title: 'Transactions per Year',
  },
];

const _data = {
  heading: {
    subheading: 'Case Study',
    heading: 'Centralized Monitoring System',
    imgUrl:
      'https://lh4.googleusercontent.com/P93dPcW2bzA11uz2IhXYmSfEUQRzXjmeIH0HXv88s-dUmHQdtPtNonxeR-h0wqoPeMU=w2400',
    paragraph: 'Development of a centralized monitoring system for healthcare tools',
  },

  about: {
    heading: 'About the Client',
    paragraph:
      'Our client’s domain is healthcare, more specifically maintaining and delivering surgery tools and implants so they were using a lot of systems that enabled them to support the full lifecycle of different services.',
    country_heading: 'Country',
    country_name: 'USA',
    industry_heading: 'Industry',
    industry_name: 'Healthcare',
  },

  domain: {
    heading: 'Domain',
    paragraph:
      'Our main goal was to create a system that would be flexible enough to connect all of the external systems that our client was already using. We wanted to be able to monitor and control them to make sure that everything was running smoothly. We had to create a system that was able to interface with all of the other systems and that was easy to use.',
  },

  challanges: {
    heading: 'Challanges',
    paragraph:
      "Some of our biggest challenges were connected with the actual diversity of systems that we needed to integrate. Some of them didn't even have open API-s, some required an extra set of rules, and some were still in development. This made integration difficult and time-consuming. However, we were eventually able to overcome these challenges and provide our users with a seamless experience.",
  },

  solution: {
    heading: 'Solution',
    paragraph:
      'From a business perspective, our client got direct income benefits, because, with this software, they could plan better, they could monitor better, and they increased the throughput of how many orders they can serve per day, from only two to more than ten.',
    imgUrl:
      'https://lh6.googleusercontent.com/A8TIbxX8ePm_ZcrZo98VWQ49YSvuq9nG07oZq4WwOgFcvjf4LjsTSUPQFQZXtof4I_4=w2400',
  },

  results: {
    heading: 'Results',
    list: [
      {
        id: 1,
        text: 'Direct communication between all independent systems',
      },
      {
        id: 2,
        text: 'Centralized history data',
      },
      {
        id: 3,
        text: 'Real-time KPI tracking',
      },
      {
        id: 4,
        text: 'Planning and scheduling in advance',
      },
      {
        id: 5,
        text: 'Reliable system',
      },
    ],
  },

  technologies: [
    {
      id: 1,
      link: net,
    },
    {
      id: 2,
      link: powerBi,
    },
    {
      id: 3,
      link: powerAutomate,
    },
    {
      id: 4,
      link: mssql,
    },
    {
      id: 5,
      link: dataFactory,
    },
  ],
};

export default function CaseStudyCentralized() {
  useEffect(() => {
    document.title = 'Case Study: Centralized Monitoring System';
  }, []);

  useAnalytics('Case Study: Centralized Monitoring System');

  return (
    <PageLayout>
      <div className="bg-baby-blue dark:bg-dg-primary-1700 w-full pt-20 md:pt-24">
        {/* Heading Section */}
        <section
          id="heading"
          className={
            "flex flex-col items-center justify-center m-auto py-16 md:py-32 relative bg-cover bg-[url('https://lh4.googleusercontent.com/P93dPcW2bzA11uz2IhXYmSfEUQRzXjmeIH0HXv88s-dUmHQdtPtNonxeR-h0wqoPeMU=w2400')] bg-no-repeat"
          }
        >
          <div className="my-8 flex flex-col md:flex-row justify-start items-center w-full max-w-custom px-8 xl:px-0">
            <div className="w-full">
              <h6 className="subheading">{_data.heading.subheading}</h6>
              <h1 className="heading text-dg-secondary mt-2">{_data.heading.heading}</h1>
              <p className="paragraph mt-4">{_data.heading.paragraph}</p>
            </div>
          </div>
        </section>

        <Wrapper padding={' py-90p'}>
          <motion.section
            id="status-numbers"
            className="flex flex-col md:flex-row items-start justify-between w-full gap-90p px-90p"
            initial={{ y: 60, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            {numbers.map((item, i) => (
              <div key={i} className="flex flex-col">
                <h2 className="display-number text-center">
                  {item.value}
                  {item.static}
                </h2>
                <h3 className="number-title text-center">{item.title}</h3>
              </div>
            ))}
          </motion.section>
        </Wrapper>

        {/* About the Client Section */}
        <section id="client" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col md:flex-row justify-center items-start w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full md:w-1/2">
              <h2 className="h3-heading text-teal-600">{_data.about.heading}</h2>
              <p className="paragraph mt-4">{_data.about.paragraph}</p>
            </div>
            <div className="w-full md:w-1/2 grid grid-cols-2 md:grid-cols-3 gap-16 mt-8 md:mt-0">
              <div className="hidden md:inline-block"></div>
              <div className="float-left md:float-right text-left md:text-right">
                <h3 className="text-teal-600 font-semibold">
                  {_data.about.country_heading}
                </h3>
                <p className="mt-4">{_data.about.country_name}</p>
              </div>
              <div className="float-left md:float-right text-left md:text-right">
                <h3 className="text-teal-600 font-semibold">
                  {_data.about.industry_heading}
                </h3>
                <p className="mt-4">{_data.about.industry_name}</p>
              </div>
            </div>
          </div>
        </section>

        {/* Domain Section */}
        <section id="domain" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading">{_data.domain.heading}</h3>
              <p className="paragraph mt-4">{_data.domain.paragraph}</p>
            </div>
          </div>
        </section>

        {/* Challanges, Solution Section */}
        <section
          id="challanges_solution"
          className="flex flex-col items-center justify-center mt-16"
        >
          <div className="my-8 flex flex-col md:flex-row justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full md:w-1/2 md:pr-16">
              <div>
                <h3 className="h3-heading">{_data.challanges.heading}</h3>
                <p className="text-sm text-dark-gray dark:text-white mt-4">
                  {_data.challanges.paragraph}
                </p>
              </div>
              <div className="mt-8">
                <h3 className="h3-heading">{_data.solution.heading}</h3>
                <p className="text-sm text-dark-gray dark:text-white mt-4">
                  {_data.solution.paragraph}
                </p>
              </div>
            </div>
            <img
              src={_data.solution.imgUrl}
              alt="Case Study main image"
              className="text-center w-full md:w-1/2"
            />
          </div>
        </section>

        {/* Results Section */}
        <section id="results" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading text-dg-secondary">{_data.results.heading}</h3>
              <ul className="list-disc paragraph mt-2 pl-8">
                {_data.results.list.map(item => (
                  <li key={item.id}>{item.text}</li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        {/*Technologies Section */}
        <section id="technologies" className="flex flex-col  mt-16">
          <div className="my-8 flex flex-col w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading">Technologies</h3>
            </div>
            <TechNuggets tech={['.Net', 'MS SQL Server', 'JQuery', 'Power BI']} />
          </div>
        </section>

        {/* CTA Section */}
        <section id="cta" className="flex flex-col items-center justify-center mt-16">
          <div className="px-8 mt-8 mb-32 w-full max-w-custom">
            <ActionCard
              title="Let's Work Together!"
              text="Grow faster with a dedicated team of .NET & JS experts."
              btn1="More Projects"
              btn2="Contact Us"
              link1={'/portfolio'}
              link2={'/contact'}
            />
          </div>
        </section>
      </div>
    </PageLayout>
  );
}
