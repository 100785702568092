import React from 'react'
import { ReactComponent as TimelineSVG } from './../assets/TimelineSVG.svg';

const TimelineLogo = () => {
  return (
    <div className="w-full relative flex flex-col h-[90%]">
        <div className='sticky top-[40%] w-full z-20'>
            <TimelineSVG className='ml-[-52px] lg:m-auto scale-75 lg:scale-100' />
        </div>
    </div>
  )
}

export default TimelineLogo