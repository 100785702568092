import React from 'react';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../layout/Wrapper';
import PageTitle from './shared/PageTitle';
import ProcessCard from './shared/ProcessCard';
import ProcessCardsWrapper from './shared/ProcessCardWrapper';
import PortfolioDataLayer from '../api/PortfolioDataLayer';

function PortfolioSection({heading,cta}) {
  const linkTo = useNavigate();

  return (
    <Wrapper padding={' py-90p'} bg>
      <div className="py-32p">
        <PageTitle left heading={heading.title} subheading={heading.subtitle} />
      </div>
      <PortfolioDataLayer filter={'&sort[0]=id&pagination[pageSize]=3'} />
      <div className="flex justify-center text-center">
        <button
          className="btn text-dg-secondary mt-4"
          onClick={() => linkTo('/portfolio')}
        >
          See More...
        </button>
      </div>
    </Wrapper>
  );
}

export default PortfolioSection;
