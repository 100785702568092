import React from 'react';
import CustomLink from './root/CustomLink';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { useState, useRef } from 'react';

const WorkClientForm = ({ data, download, onValidated, message, status }) => {
  const container = useRef();
  const [suc, setSuc] = useState(false);
  const [form, setForm] = useState({
    name: '',
    surname: '',
    email: '',
  });

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, 'First name too short')
      .max(50, 'First name too long')
      .required('First Name is Required'),
    email: Yup.string().email('Invalid email format').required('Email is Required'),
    surname: Yup.string()
      .min(2, 'Last name too short')
      .max(50, 'Last name too long')
      .required('Last Name is Required'),
  });

  return (
    <div
      ref={container}
      className="max-w-[600px] mx-[16px] md:mx-auto md:mt-[-320px] p-[24px] flex flex-col gap-[24px] z-20 bg-white rounded-[16px] shadow-[0_3px_10px_rgb(0,0,0,0.2)] md:shadow-none"
    >
      <div className="flex flex-col gap-[8px]">
        <h6 className="n-h3-heading text-dark-gray font-bold">{data.title}</h6>
        <p className="n-paragraph">{data.paragraph}</p>
        <Formik
          initialValues={form}
          validationSchema={validationSchema}
          onSubmit={async values => {
            setForm({
              ...form,
              name: values.name,
              surname: values.surname,
              email: values.email,
            });
            onValidated({
              FNAME: values.name,
              LNAME: values.surname,
              EMAIL: values.email,
            });
          }}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <div className=" sm:rounded-md">
                <div className="py-2 sm:py-6">
                  <div className="">
                    <div className="col-span-1 sm:col-span-1">
                      <div className="py-1">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                        >
                          First name
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          onChange={props.handleChange}
                          value={props.values.name}
                          autoComplete="given-name"
                          className="mt-1 focus:ring-dg-primary-600 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                        />
                        <div className="h-4">
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-sm text-right text-red-600"
                          />
                        </div>
                      </div>
                      <div className="py-1">
                        <label
                          htmlFor="surname"
                          className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                        >
                          Last name
                        </label>
                        <input
                          type="text"
                          name="surname"
                          id="surname"
                          onChange={props.handleChange}
                          value={props.values.surname}
                          autoComplete="family-name"
                          className="mt-1 focus:ring-dg-primary-900 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                        />
                        <div className="h-4">
                          <ErrorMessage
                            name="surname"
                            component="div"
                            className="text-sm text-right text-red-600"
                          />
                        </div>
                      </div>
                      <div className="py-1">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          onChange={props.handleChange}
                          value={props.values.email}
                          autoComplete="email"
                          className="mt-1 focus:ring-dg-primary-900 focus:border-dg-primary-900 dark:bg-dg-primary-1500 dark:text-white dark:autofill:text-white dark:autofill:bg-dg-primary-1500 block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                        />
                        <div className="h-4">
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-sm text-right text-red-600"
                          />
                        </div>
                      </div>

                      <div className=" py-3 text-right">
                        <button
                          type="submit"
                          className="btn-primary w-fit items-center n-paragraph-title text-white rounded-[16px]"
                        >
                          Subscribe and Download
                        </button>
                        <div className={"text-sm text-right" + (status === 'success' ? ' text-dg-primary-900' : ' text-red-600')}>{message}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default WorkClientForm;
