import { useState, useEffect, useRef } from 'react';

export default function useHoverOutside(initialIsVisible) {
    const [show, setShow] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleHoverOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mouseover', handleHoverOutside, true);
        return () => {
            document.removeEventListener('mouseover', handleHoverOutside, true);
        };
    }, []);

    return { ref, show, setShow };
}