import React from 'react'
import {Link} from 'react-router-dom';

const CustomLink = ({href, downloadFile, bg, txt, children}) => {


  return (
    <div className='btn-primary w-fit items-center n-paragraph-title text-white rounded-[16px]'>{children}</div>
    // <Link {...{
    //     className: 'btn-primary w-fit items-center n-paragraph-title text-white rounded-[16px]' ,
    //     to: href,
    //     target: '_blank',
    //     ...(downloadFile && {download: downloadFile}),
    //   }}>
    //     {children} 
    // </Link>
  )
}

export default CustomLink