import { useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import propTypes from 'prop-types';


export default function HashPositions(props) {
  function handleChange(event) {
    props.setSelectedPosition(event.target.value)
  }

  return (
    <RadioGroup
      value={props.selectedPosition}
      onChange={props.setSelectedPosition}
      className="flex flex-col items-start justify-center"
    >
      <RadioGroup.Label className="block text-sm font-medium text-gray-700 dark:text-gray-400 mr-4">
        Select Position
      </RadioGroup.Label>
      <div className="mt-1 mb-4 flex flex-row items-center justify-start">
        {props.data.map(job => (
          <RadioGroup.Option key={job.id} value={job.attributes.tag}>
            {({ checked }) => (
              <span
                onClick={() => {
                  props.setOtherInputState(true);
                  handleChange(event);
                  props.hashToFormData(props.selectedPosition);
                }}
                className={
                  checked
                    ? 'transition-all bg-dg-primary-900 rounded-lg py-1 px-3 text-white text-sm mr-2'
                    : 'transition-all px-3 mr-2 text-sm hover:cursor-pointer hover:bg-opacity-100 bg-opacity-50 py-1 bg-dg-primary-900 text-white rounded-lg hover:transition-all'
                }
              >
                {'#' + job.attributes.tag}
              </span>
            )}
          </RadioGroup.Option>
        ))}

        <RadioGroup.Option value="#other">
          {({ checked }) => (
            <span
              onClick={() => {
                props.setOtherInputState(false);
                props.setSelectedPosition('');
                props.hashToFormData(props.selected);
              }}
              className={
                checked
                  ? 'transition-all bg-dg-primary-900 rounded-lg py-1 px-3 text-white text-sm'
                  : 'transition-all px-3 text-sm hover:cursor-pointer hover:bg-opacity-100 bg-opacity-50 py-1 bg-dg-primary-900 text-white rounded-lg hover:transition-all'
              }
            >
              #other
            </span>
          )}
        </RadioGroup.Option>
      </div>
    </RadioGroup>
  );
}

HashPositions.propTypes = {
  otherInputState: propTypes.boolean,
  defaultPositionSelection: propTypes.string,
  setSelectedPosition: propTypes.func,
};
