import PropTypes from 'prop-types';
import Hex from './../../assets/icons/life/hexLife.svg';

export default function CardLife({ heading, paragraph, number }) {
  return (
    <div className="relative flex flex-col items-start text-left card-plain w-full">
      <div className="absolute top-8 left-2 z-10">
        <img src={Hex} alt="Purple hex backgorund" />
      </div>
      <div className="absolute top-10 left-6 z-20">
        <p className="paragraph text-white">{number}.</p>
      </div>
      <div className="flex flex-row justify-start items-start">
        <h2 className="text-xl font-semibold dark:text-white ml-2">{heading}</h2>
      </div>
      <p className="text-sm text-dark-gray dark:text-white mt-2">{paragraph}</p>
    </div>
  );
}

CardLife.propTypes = {
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  number: PropTypes.number,
};
