import React from 'react'
import Wrapper from '../layout/Wrapper'
import PageTitle from './shared/PageTitle'
import StackWrapper from './StackWrapper'

const _data = {
    heading: "Tech Stack",
    subheading: "What we speak",
    paragraph: "And many more..."
}

function TechStack({heading, btn}) {
  return (
    <Wrapper bg padding={" py-90p"}>
        <div className='flex flex-col gap-90p justify-center items-center'>
            <div className='w-full'>
                <PageTitle heading={heading.title} subheading={heading.subtitle} left />
            </div>
            <div className='w-full'>
                <StackWrapper />    
            </div> 
            <p className='paragraph'>{btn}</p>
        </div>
        
    </Wrapper>
  )
}

export default TechStack