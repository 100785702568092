import propTypes from 'prop-types';

export default function PageHeading(props) {
  return (
    <div className="flex flex-col gap-8p items-center justify-center">
      <div className="mb-32p lg:mb-64p flex flex-col gap-8p items-center justify-center">
          <h2 className="subheading">{props.subheading}</h2>
          <h3 className="heading text-center">{props.heading}</h3>
      </div>
      <div className="flex flex-col gap-16p">
        <p className="paragraph text-start">{props.paragraph1}</p>
        <p className="paragraph text-start">{props.paragraph2}</p>
      </div>
    </div>
  );
}

PageHeading.propTypes = {
  heading: propTypes.string,
  subheading: propTypes.string,
  paragraph: propTypes.string,
};
