import HoverServices from './shared/graphics/HoverServices';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';

import Animation_Diligent from './../assets/animation_diligent.webm';

import './../styles/hexagon.css';
import Wrapper from '../layout/Wrapper';

const api_url = process.env.REACT_APP_API_URL;

export default function ServicesHome() {
  const [hoverIndex, SetHoverIndex] = useState(0);
  const [change, SetChange] = useState(true);

  const [circle, SetCircle] = useState(0);
  const [triangle, SetTriangle] = useState(140);
  const [hex, SetHex] = useState(270);
  //const [angleTriangle, SetAngleTriangle] = useState(0);
  //const [angleHex, SetAngleHex] = useState(0);

  const [cntServicesHome, setCntServicesHome] = useState('');
  const [isLoaded, setIsLoaded] = useState('');
  const [text, setText] = useState(
    'Expand your engineering team effectively with our staff augmentation service. Diligent helps to build dedicated teams tailored to the needs of your project to scale the capacity of your business.',
  );
  const [ico, setIco] = useState(
    `${api_url}/uploads/Group_492_1_0ab4463650.png`,
  );

  useEffect(() => {
    var vid = document.getElementById('sscreen');
    vid.playbackRate = 2;
  }, []);

  useEffect(async () => {
    axios
      .get(
        `${api_url}/api/homepage?populate[0]=services&populate[1]=services.heading&populate[2]=services.serviceHome.icon`,
      )
      .then(res => {
        setCntServicesHome(res.data.data.attributes);
        setIsLoaded(true);
      })
      .catch(err => {
        console.log(err);
        setIsLoaded(false);
      });

    if (hoverIndex > 0) {
      SetCircle(circle + 30);
      SetHex(hex + 30);
      SetTriangle(triangle + 30);
    }
  }, [hoverIndex]);

  // useEffect(() => {
  //   if (change === true) {
  //     const timer = setTimeout(() => SetChange(true), 1000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [hoverIndex]);

  function handleHover(event) {
    if (change == true) {
      SetChange(false);
      SetHoverIndex(event.target.getAttribute('domIndex'));
      const timer = setTimeout(() => {
        SetChange(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }

  if (!isLoaded) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="sscreen" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  }

  return (
    <section
      id="services"
      className="bg-white dark:bg-dg-primary-1700 flex flex-col justify-center items-center py-164p"
    >
      <Wrapper>
      <motion.div className="flex justify-center items-center w-full z-20 "
              initial={{ y:60, opacity: 0 }}
              whileInView={{ y:0, opacity: 1 }}
              transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <div className="flex flex-col justify-center items-left gap-8p text-left">
          {/* Our Services Heading */}
          <h6 className="subheading">
            {cntServicesHome.services.heading.subheading}
          </h6>
          <h1 className="heading">
            {cntServicesHome.services.heading.heading}
          </h1>
          {/* Our Services Links */}
          <div className="flex flex-col md:flex-row text-center align-center justify-center mt-16 relative">
            <div className="hidden h-[80%] w-[2px] bg-dg-primary-900 text-dg-primary-900 dark:text-white absolute top-4 bottom-12 left-[6]"></div>
            <ul className="flex flex-col text-left w-full lg:w-1/2 list-none z-10">
              {cntServicesHome.services.serviceHome.map((service, i) => (
                <li
                  key={service.id}
                  domIndex={service.id}
                  className="group flex flex-row items-center w-fit px-4 mb-4 text-md dark:text-white font-semibold transition-all hover:shadow-md rounded-xl hover:bg-white dark:hover:bg-dg-primary-1600 dark:hover:border dark:hover:border-solid dark:hover:border-dg-primary-1500 p-2 hover:transition-all"
                  onMouseEnter={e => {
                    handleHover(e);
                    setText(service.paragraph);
                    setIco(api_url + service.icon.data.attributes.formats.thumbnail.url);
                  }}
                >
                  {i + 1 !== cntServicesHome.services.serviceHome.length && (
                    <hr className="-z-10 absolute bg-dg-primary-900 rotate-90 w-12 mt-12 -ml-4" />
                  )}
                  <div className="hexagon transition-all group-hover:bg-dg-primary-900 group-hover:rotate-90 group-hover:transition-all"></div>
                  <div
                    className="min-w-max pl-2"
                  >
                    {service.heading}
                  </div>
                  
                </li>
              ))}
            </ul>
            <div
              id="services_content"
              className="hidden md:block w-full lg:w-1/2 ml-0 lg:ml-16 mt-8 flex flex-col items-center justify-center"
            >
              <div className="hidden lg:block absolute scale-75 md:scale-100 top-[-80px] right-[-100px]">
                <HoverServices circle={circle} triangle={triangle} hex={hex} />
              </div>

              <div
                key={cntServicesHome.services.serviceHome[0].id}
                domIndex={cntServicesHome.services.serviceHome[0].id}
                className="hidden md:flex w-full flex-col justify-center items-center"
              >
                <div className='w-[72px] min-h-[80px]'>
                <img src={ico} alt="Service's Icon" width={72} />
                </div>
               
                <p className="mt-3 text-sm dark:text-white">{text}</p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      </Wrapper>
    </section>
  );
}
