import ActionCard from '../components/shared/ActionCard';
import PageLayout from '../layout/PageLayout';
import strata from './../assets/images/CaseStudy/Strata.jpg';
import strataSmall from './../assets/images/CaseStudy/StrataSmall.png';

import net from './../assets/icons/caseStudy/net.svg';
import jquery from './../assets/icons/caseStudy/jquery.svg';
import react from './../assets/icons/caseStudy/react-native.svg';
import mssql from './../assets/icons/caseStudy/MSSQL.svg';
import { useEffect } from 'react';
import { motion } from 'framer-motion';
import Wrapper from '../layout/Wrapper';
import TechNuggets from '../components/shared/TechNuggets';
import useAnalytics from '../hooks/useAnalytics';

const numbers = [
  {
    value: '10k',
    static: '+',
    title: 'Users',
  },
  {
    value: '230k',
    static: '+',
    title: 'Tests Ordered',
  },
  {
    value: '200k',
    static: '+',
    title: 'Laboratory Results',
  },
];

const _data = {
  heading: {
    subheading: 'Case Study',
    heading: 'Health Tracking Software',
    imgUrl: strata,
    paragraph: 'COVID and Health Tracking Business Software',
  },

  about: {
    heading: 'About the Client',
    paragraph:
      'COVID-19 has had a profound impact on the way we think about health and wellness. A US startup has developed a comprehensive wellness and health management platform that could also include integrated diagnostic testing. This cemented the need for such a platform as the pandemic hit.',
    country_heading: 'Country',
    country_name: 'USA',
    industry_heading: 'Industry',
    industry_name: 'Healthcare',
  },

  domain: {
    heading: 'Domain',
    paragraph:
      "The client's idea was created by U.S. healthcare executives who saw firsthand the need for a comprehensive wellness and health management platform that could also include integrated diagnostic testing.",
  },

  challanges: {
    heading: 'Challanges',
    paragraph:
      'We had to work with impossible deadlines, constantly changing requirements, and diversity of systems that we needed to integrate. We had to integrate with shipping services, tracking services, laboratories, financial services, and a lot more in a short time frame.',
  },

  solution: {
    heading: 'Solution',
    paragraph:
      'To fulfill all requirements, we made a flexible application that consists of 3 parts: Admin portal, User portal, and User mobile app.',
    imgUrl: 'https://i.postimg.cc/L43QFftg/Strata-Small.png',
  },

  results: {
    heading: 'Results',
    list: [
      {
        id: 1,
        text: 'Web-Based Admin / User Portal',
      },
      {
        id: 2,
        text: 'Deliver the product within an established amount of time and defined budgets',
      },
      {
        id: 3,
        text: 'Integration with National Lab Network',
      },
      {
        id: 4,
        text: 'Customized Health Screenings',
      },
    ],
  },

  technologies: [
    {
      id: 1,
      link: net,
    },
    {
      id: 2,
      link: jquery,
    },
    {
      id: 3,
      link: mssql,
    },
    {
      id: 4,
      link: react,
    },
  ],
};

export default function CaseStudyStrata() {
  useEffect(() => {
    document.title = 'Case Study: Healthcare Tracking Software';
  }, []);

  useAnalytics('Case Study: Healthcare Tracking Software');

  return (
    <PageLayout>
      <div className="bg-baby-blue dark:bg-dg-primary-1700 w-full pt-20 md:pt-24">
        {/* Heading Section */}
        <section
          id="heading"
          className={
            "flex flex-col items-center justify-center m-auto py-16 md:py-32 relative bg-cover bg-[url('https://i.postimg.cc/ZK08syVS/Strata.jpg')] bg-no-repeat"
          }
        >
          <div className="my-8 flex flex-col md:flex-row justify-start items-center w-full max-w-custom px-8 xl:px-0">
            <div className="w-full">
              <h6 className="subheading">{_data.heading.subheading}</h6>
              <h1 className="heading text-dg-secondary mt-2">{_data.heading.heading}</h1>
              <p className="paragraph mt-4">{_data.heading.paragraph}</p>
            </div>
          </div>
        </section>

        <Wrapper padding={' py-90p'}>
          <motion.section
            id="status-numbers"
            className="flex flex-col md:flex-row items-start justify-between w-full gap-90p px-90p"
            initial={{ y: 60, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            {numbers.map((item, i) => (
              <div key={i} className="flex flex-col">
                <h2 className="display-number text-center">
                  {item.value}
                  {item.static}
                </h2>
                <h3 className="number-title text-center">{item.title}</h3>
              </div>
            ))}
          </motion.section>
        </Wrapper>

        {/* About the Client Section */}
        <section id="client" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col md:flex-row justify-center items-start w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full md:w-1/2">
              <h2 className="h3-heading text-teal-600">{_data.about.heading}</h2>
              <p className="paragraph mt-4">{_data.about.paragraph}</p>
            </div>
            <div className="w-full md:w-1/2 grid grid-cols-2 md:grid-cols-3 gap-16 mt-8 md:mt-0">
              <div className="hidden md:inline-block"></div>
              <div className="float-left md:float-right text-left md:text-right">
                <h3 className="text-teal-600 font-semibold">
                  {_data.about.country_heading}
                </h3>
                <p className="mt-4">{_data.about.country_name}</p>
              </div>
              <div className="float-left md:float-right text-left md:text-right">
                <h3 className="text-teal-600 font-semibold">
                  {_data.about.industry_heading}
                </h3>
                <p className="mt-4">{_data.about.industry_name}</p>
              </div>
            </div>
          </div>
        </section>

        {/* Domain Section */}
        <section id="domain" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading">{_data.domain.heading}</h3>
              <p className="paragraph mt-4">{_data.domain.paragraph}</p>
            </div>
          </div>
        </section>

        {/* Challanges, Solution Section */}
        <section
          id="challanges_solution"
          className="flex flex-col items-center justify-center mt-16"
        >
          <div className="my-8 flex flex-col md:flex-row justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full md:w-1/2 md:pr-16">
              <div>
                <h3 className="h3-heading">{_data.challanges.heading}</h3>
                <p className="text-sm text-dark-gray dark:text-white mt-4">
                  {_data.challanges.paragraph}
                </p>
              </div>
              <div className="mt-8">
                <h3 className="h3-heading">{_data.solution.heading}</h3>
                <p className="text-sm text-dark-gray dark:text-white mt-4">
                  {_data.solution.paragraph}
                </p>
              </div>
            </div>
            <img
              src={_data.solution.imgUrl}
              alt="Case Study main image"
              className="text-center w-full md:w-1/2"
            />
          </div>
        </section>

        {/* Results Section */}
        <section id="results" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading text-dg-secondary">{_data.results.heading}</h3>
              <ul className="list-disc paragraph mt-2 pl-8">
                {_data.results.list.map(item => (
                  <li key={item.id}>{item.text}</li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        {/* Technologies Section */}
        <section id="technologies" className="flex flex-col mt-16">
          <div className="my-8 flex flex-col w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading">Technologies</h3>
            </div>
            <TechNuggets tech={['C#', 'MVC', 'SQL', 'Ajax', 'React Native']} />
          </div>
        </section>

        {/* CTA Section */}
        <section id="cta" className="flex flex-col items-center justify-center mt-16">
          <div className="px-8 mt-8 mb-32 w-full max-w-custom">
            <ActionCard
              title="Let's Work Together!"
              text="Grow faster with a dedicated team of .NET & JS experts."
              btn1="More Projects"
              btn2="Contact Us"
              link1={'/portfolio'}
              link2={'/contact'}
            />
          </div>
        </section>
      </div>
    </PageLayout>
  );
}
