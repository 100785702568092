import React from 'react'
import Wrapper from '../layout/Wrapper';
import PageTitle from './shared/PageTitle';

const data = {
    heading: 'Come and Meet Us!',
    subheading: 'our office',
}

const MapDilig = ({heading}) => {
    return (
        <Wrapper padding={' py-90p'}>
            <PageTitle heading={heading.title} subheading={heading.subtitle} />
            <div className='w-full py-90p'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2903.249778965247!2d21.90917771571484!3d43.30903038263504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4755b098fd2d0343%3A0x25c74e5a7a3a0530!2z0JfQtdGC0YHQutCwIDM2LCDQndC40Yg!5e0!3m2!1ssr!2srs!4v1666617514841!5m2!1ssr!2srs" width="100%" height="450" loading="lazy"></iframe>
            </div>
        </Wrapper>
    )
}

export default MapDilig;