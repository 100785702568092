import ActionCard from '../components/shared/ActionCard';
import PageLayout from '../layout/PageLayout';

import net from './../assets/icons/caseStudy/net.svg';
import powerBi from './../assets/icons/caseStudy/power-bi.svg';
import powerAutomate from './../assets/icons/caseStudy/power-automate.svg';
import mssql from './../assets/icons/caseStudy/MSSQL.svg';
import dataFactory from './../assets/icons/caseStudy/data-factory.svg';
import { useEffect } from 'react';
import { motion } from 'framer-motion';
import Wrapper from '../layout/Wrapper';
import TechNuggets from '../components/shared/TechNuggets';
import useAnalytics from '../hooks/useAnalytics';

const numbers = [
  {
    value: 6,
    static: '+',
    title: 'Team Members',
  },
  {
    value: 6,
    static: '+',
    title: 'Months Project duration',
  },
];

const _data = {
  heading: {
    subheading: 'Case Study',
    heading: 'Resource Planning System',
    imgUrl:
      'https://lh3.googleusercontent.com/b9F35_JPlulVv0jS3UAYKJ2z6gz_AsWprQef_1wWTFGJlhEyLGfA1AthyUcdgrm1Qdk=w2400',
    paragraph: 'Enterprise Resource Planning System for Law Offices',
  },

  about: {
    heading: 'About the Client',
    paragraph:
      'Stankovic & Partners Law Office has the qualifications and skills needed to provide high-quality legal services. The firm has a team of experienced attorneys who are familiar with a wide range of legal issues and can provide comprehensive legal assistance to their clients. In addition, the firm has a well-established reputation for providing excellent customer service and is committed to providing each client with the individualized attention they deserve.',
    country_heading: 'Country',
    country_name: 'Serbia',
    industry_heading: 'Industry',
    industry_name: 'Law',
  },

  domain: {
    heading: 'Domain',
    paragraph:
      'To streamline their law practice business, our main goal was to create one system that would house all data and events daily encountered, such as contacts, clients, hearings, cases, court staff, scheduling, and planning. Having one central location for all this information will allow the law firm to easily keep track of everything and make changes or adjustments as needed.',
  },

  challanges: {
    heading: 'Challanges',
    paragraph:
      'Some of our biggest challenges were implementing a resource-optimized web application that united requirements and provided a single point for creating and viewing entities (contacts, clients, hearings, cases...). By doing this, we were able to not only improve efficiency but also reduce costs.',
  },

  solution: {
    heading: 'Solution',
    paragraph:
      'With this software, our client could plan and organize better. They could monitor their work orders better and they moved from using multiple different software and a lot of spreadsheets to one centralized system.',
    imgUrl:
      'https://lh4.googleusercontent.com/Ajfa7b-MKsyg8JAkaXQJNnskEKJGiBaK7YxlbRIIoKmtSut8jHRm7Dxxw6a7y-gepD4=w2400',
  },

  results: {
    heading: 'Results',
    list: [
      {
        id: 1,
        text: 'Uploading documents for every case and for every hearing',
      },
      {
        id: 2,
        text: 'Creating unique database',
      },
      {
        id: 3,
        text: 'Creating invoices and billing',
      },
      {
        id: 4,
        text: 'Centralized history data for every case',
      },
      {
        id: 5,
        text: 'Reliable system',
      },
    ],
  },

  technologies: [
    {
      id: 1,
      link: net,
    },
    {
      id: 2,
      link: powerBi,
    },
    {
      id: 3,
      link: powerAutomate,
    },
    {
      id: 4,
      link: mssql,
    },
    {
      id: 5,
      link: dataFactory,
    },
  ],
};

export default function CaseStudyResource() {
  useEffect(() => {
    document.title = 'Case Study: Resource Planning System';
  }, []);

  useAnalytics('Case Study: Resource Planning System');

  return (
    <PageLayout>
      <div className="bg-baby-blue dark:bg-dg-primary-1700 w-full pt-20 md:pt-24">
        {/* Heading Section */}
        <section
          id="heading"
          className="flex flex-col items-center justify-center m-auto py-16 md:py-32 bg-[url('https://lh3.googleusercontent.com/b9F35_JPlulVv0jS3UAYKJ2z6gz_AsWprQef_1wWTFGJlhEyLGfA1AthyUcdgrm1Qdk=w2400')] bg-cover"
        >
          <div className="my-8 flex flex-col md:flex-row justify-start items-center w-full max-w-custom px-8 xl:px-0">
            <div className="w-full">
              <h6 className="subheading">{_data.heading.subheading}</h6>
              <h1 className="heading text-dg-secondary mt-2">{_data.heading.heading}</h1>
              <p className="paragraph mt-4">{_data.heading.paragraph}</p>
            </div>
          </div>
        </section>

        <Wrapper padding={' py-90p'}>
          <motion.section
            id="status-numbers"
            className="flex flex-col md:flex-row items-start justify-between w-full gap-90p px-90p"
            initial={{ y: 60, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            {numbers.map((item, i) => (
              <div key={i} className="flex flex-col">
                <h2 className="display-number text-center">
                  {item.value}
                  {item.static}
                </h2>
                <h3 className="number-title text-center">{item.title}</h3>
              </div>
            ))}
          </motion.section>
        </Wrapper>

        {/* About the Client Section */}
        <section id="client" className="flex flex-col items-center justify-center">
          <div className="my-8 flex flex-col md:flex-row justify-center items-start w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full md:w-1/2">
              <h3 className="h3-heading text-teal-600">{_data.about.heading}</h3>
              <p className="paragraph mt-4">{_data.about.paragraph}</p>
            </div>
            <div className="w-full md:w-1/2 grid grid-cols-2 md:grid-cols-3 gap-16 mt-8 md:mt-0">
              <div className="hidden md:inline-block"></div>
              <div className="float-left md:float-right text-left md:text-right">
                <h4 className="text-teal-600 font-semibold">
                  {_data.about.country_heading}
                </h4>
                <p className="mt-4">{_data.about.country_name}</p>
              </div>
              <div className="float-left md:float-right text-left md:text-right">
                <h4 className="text-teal-600 font-semibold">
                  {_data.about.industry_heading}
                </h4>
                <p className="mt-4">{_data.about.industry_name}</p>
              </div>
            </div>
          </div>
        </section>

        {/* Domain Section */}
        <section id="domain" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading">{_data.domain.heading}</h3>
              <p className="paragraph mt-4">{_data.domain.paragraph}</p>
            </div>
          </div>
        </section>

        {/* Challanges, Solution Section */}
        <section
          id="challanges_solution"
          className="flex flex-col items-center justify-center mt-16"
        >
          <div className="my-8 flex flex-col md:flex-row justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full md:w-1/2 md:pr-16">
              <div>
                <h3 className="h3-heading">{_data.challanges.heading}</h3>
                <p className="text-sm text-dark-gray dark:text-white mt-4">
                  {_data.challanges.paragraph}
                </p>
              </div>
              <div className="mt-8">
                <h3 className="h3-heading">{_data.solution.heading}</h3>
                <p className="text-sm text-dark-gray dark:text-white mt-4">
                  {_data.solution.paragraph}
                </p>
              </div>
            </div>
            <img
              src={_data.solution.imgUrl}
              alt="Case Study main image"
              className="text-center w-full md:w-1/2"
            />
          </div>
        </section>

        {/* Results Section */}
        <section id="results" className="flex flex-col items-center justify-center mt-16">
          <div className="my-8 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading text-dg-secondary">{_data.results.heading}</h3>
              <ul className="list-disc paragraph mt-2 pl-8">
                {_data.results.list.map(item => (
                  <li key={item.id}>{item.text}</li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        {/* Technologies Section */}
        <section id="technologies" className="flex flex-col mt-16">
          <div className="my-8 flex flex-col w-full max-w-custom m-auto px-8 xl:px-0">
            <div className="w-full">
              <h3 className="h3-heading">Technologies</h3>
            </div>
            <TechNuggets tech={['.Net', 'MS SQL Server', 'React Native', 'React.JS']} />
          </div>
        </section>

        {/* CTA Section */}
        <section id="cta" className="flex flex-col items-center justify-center mt-16">
          <div className="px-8 mt-8 mb-32 w-full max-w-custom">
            <ActionCard
              title="Let's Work Together!"
              text="Grow faster with a dedicated team of .NET & JS experts."
              btn1="More Projects"
              btn2="Contact Us"
              link1={'/portfolio'}
              link2={'/contact'}
            />
          </div>
        </section>
      </div>
    </PageLayout>
  );
}
