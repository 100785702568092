import React from 'react';
import {
  SliderProvider,
  SliderTrack,
  Slide,
  SliderButton,
  DotsNav,
} from '@faceless-ui/slider';
import img1 from './../../../assets/images/Slider/1.png';
import img2 from './../../../assets/images/Slider/2.png';
import img3 from './../../../assets/images/Slider/3.png';
import img4 from './../../../assets/images/Slider/4.png';
import img5 from './../../../assets/images/Slider/5.png';
import img6 from './../../../assets/images/Slider/6.png';
import img7 from './../../../assets/images/Slider/7.png';
import img8 from './../../../assets/images/Slider/8.png';
import img9 from './../../../assets/images/Slider/9.png';
import img10 from './../../../assets/images/Slider/10.png';
import './index.css';

const slides = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];

const FacelessSlider = () => {
  return (
    <SliderProvider slidesToShow={3} autoPlay scrollSnap dragScroll>
      <div className={''}>
        {/* <SliderButton direction="prev">
        Previous
      </SliderButton>
      <SliderButton direction="next">
        Next
      </SliderButton> */}
      </div>
      <div className="rounded-xl overflow-hidden mt-4">
        <SliderTrack className={' track h-full mt-10 rounded-xl'}>
          {slides.map((slide, index) => (
            <Slide key={index} index={index} className={'h-full'}>
              <img src={slide} alt="Our Team's image" className={'w-full'}></img>
            </Slide>
          ))}
        </SliderTrack>
      </div>

      {/* <DotsNav
            className={"dots"}
            dotClassName={"dot"}
            activeDotClassName={"dotIsActive"}
          /> */}
    </SliderProvider>
  );
};

export default FacelessSlider;
