import React, { useEffect, useLayoutEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as TimelineLine } from './../assets/TimelineLine.svg';
import { ReactComponent as TimelineSVG } from './../assets/TimelineSVG.svg';
import { useElementScroll, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';
import { useInView, motion } from 'framer-motion';

const TimelineCardsWrapper2 = ({ children }) => {
  const ref = useRef(null);
/////
//   const inView = useInView(ref, {
//     margin: '0px 100px -50% 0px',
//   });
/////
  const { scrollYProgress } = useScroll();
  const [scroll, setScroll] = useState(0);
  const [v, setV] = useState(0);
  const [view, setView] = useState(false);
/////
  const [y, setY] = useState(window.scrollY);
  const [top,setTop] = useState(0);
  const [docH,setDocH] = useState(0);
/////
  const handleNavigation = useCallback(
    e => {
        const doc = document.documentElement.offsetHeight
      const window = e.currentTarget;
    
        if (y > window.scrollY) {

            //up
             if (view && top >= 0) setTop(prev => prev  - (y - window.scrollY));
           } else if (y < window.scrollY) {
            //down
             if (view) setTop(prev => prev  - (y - window.scrollY));
           }
           setY(window.scrollY);
      
     
    },
    [y],
  );



  // useEffect(() => {
  //   setY(window.scrollY);
  //   window.addEventListener('scroll', handleNavigation);

  //   return () => {
  //     window.removeEventListener('scroll', handleNavigation);
  //   };
  // }, [handleNavigation]);
/////

  useLayoutEffect(() => {
    const temp1 = ref.current.getBoundingClientRect();
    const top = temp1.top;
    const temp2 = ref.current.getBoundingClientRect();
    const bottom = temp2.bottom;
    const ask = top < window.innerHeight / 2;
    setView(ask);
    const h = window.document.body.offsetHeight;
    setDocH(h);
    const widnow2 = document.documentElement.offsetHeight
  });

  const [again,setAgain] = useState("");

//   function handleScroll() {
//     //var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
//   var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
//   var scrolled = (y / height) * 100;
//   document.getElementById("myBar").style.top = scrolled + "%";
//   setAgain(scrolled + "%");
//   }

  const handleScroll  = useCallback(
    () => {  
        var winScroll = document.body.height || document.documentElement.scrollTop; 
        var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        var scrolled = (winScroll / height) * 100;
        //document.getElementById("myBar").style.top = scrolled + "%";
        setAgain(scrolled + "%");
        

    }, [y]
  )


  // useEffect(() => {
  //   setY(window.scrollY);
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [handleScroll]);

  /////
//   useEffect(() => {
//     scrollYProgress.onChange(() => {
//       setV(scrollYProgress.getVelocity());
//     });
//   }, [scrollYProgress, v]);

//   useEffect(() => {
//     if (view) setTop(scroll);
//     //else setScroll(0);
//   }, [v, view]);

 ////

//   const variants = {
//     active: {
//       y: scroll * 100,
//     },
//     inactive: {
//       y: 0,
//       transition: { duration: 1 },
//     },
//   };

  //////




  const [test, setTest] = useState(0);
  const [startMargin, setStartMargin] = useState(0);
  const [endMargin, setEndMargin] = useState(0);

  // after copy
  useLayoutEffect(() => {
    const line = ref.current.getBoundingClientRect();
    const midVH = window.innerHeight / 2;
    
    const topOfElement = y - line.top;
    const bottomOfElement = y + line.bottom;

    //setScrollDistance(topOfElement - window.scrollY);


    //if start <0 stop increment
    setStartMargin(topOfElement - y + midVH)


    // if end >0 stop increment
    setEndMargin(bottomOfElement - y - midVH)

    //setTest(topOfElement);
    //setView(ask);
    const doch = window.document.body.offsetHeight;
    //setDocH(h);
    const widnow2 = document.documentElement.offsetHeight

  });

  const handleScroll2 = useCallback(() => {


      if (startMargin > 0 && endMargin > 0) setTest(startMargin);
      setY(window.scrollY);
  }, [y]);

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleScroll2);

    return () => {
      window.removeEventListener('scroll', handleScroll2);
    };
  }, [handleScroll2]);


  return (
    <div>
      <motion.div className="w-full relative flex flex-col gap-8 lg:gap-0">
      <motion.div className="hidden md:block absolute -left-10 lg:left-[41.5%] top-[10%] w-max z-10 h-full" ref={ref}>
        <motion.div className="top-0 w-max">
          <TimelineLine className="overflow-visible" />
        </motion.div>
      </motion.div>

      {children}
    </motion.div>
    </div>
    
  );
};

TimelineCardsWrapper2.propTypes = {};

export default TimelineCardsWrapper2;
